import React from 'react'
import { MotionComponent } from '../types'
import { filterMotionProps } from '../utils'
import type { MotionProps } from './Motion'
import type { MotionVariantProps } from './MotionVariant'

type WithoutMotionProps = Omit<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (MotionProps<any> | MotionVariantProps<any>) & {
    component: MotionComponent
  },
  'as'
>

export const ComponentWithoutMotion = React.forwardRef<
  HTMLElement | SVGElement,
  WithoutMotionProps
>((props, forwardRef) => {
  const {
    children,
    component: Component,
    onAnimationStart,
    onAnimationEnd,
  } = props

  React.useEffect(() => {
    onAnimationStart?.()
    onAnimationEnd?.({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // we only want this effect to run the one time on component mount

  return (
    <Component {...filterMotionProps(props)} ref={forwardRef}>
      {children}
    </Component>
  )
})
