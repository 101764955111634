import * as React from 'react'
import {
  Header as DsUiHeader,
  Image,
  OliveImage,
  useHeaderContext,
} from '@ds/ui'
import OliveImages from '@olive/images'
import styles from '../styles'
import {
  DocusignAppId,
  HeaderHomeOnClickCallback,
  HeaderLogHandler,
  HeaderTranslateFunction,
} from '../types'
import { getAppLogoAndNameImageName } from '../utils'
import { useTimer } from '@ds/react-utils'

/*

    Icon/Name of the current app

    If the app is not a "product" (e.g, me site) then a special
    logo/name svg is used.

*/

const maximumWaitMsForOnSwitchCallback = 3000

export interface HeaderAppDescriptionProps {
  homeUrl: string // URL to load if logo is clicked
  appName?: string
  appLogoUrl?: string
  appId?: DocusignAppId
  isPhone?: boolean
  onHomeClick?: HeaderHomeOnClickCallback
  maxWaitForOnHomeClickResponse?: number
  onLoggingEvent?: HeaderLogHandler
  translate: HeaderTranslateFunction
}

export const HeaderAppDescription: React.FunctionComponent<HeaderAppDescriptionProps> =
  (props) => {
    const linkDomId = `header-home-${props.isPhone ? 'phone' : 'desktop'}`
    const { runLater } = useTimer()

    const imageName = props.appId && getAppLogoAndNameImageName(props.appId)
    const altText = props.appName
      ? props.translate('HDR:AppLogoAltText:A11Y', {
          APP_NAME: props.appName,
        })
      : props.translate('logo')

    const headerContext = useHeaderContext()

    if (imageName || props.appLogoUrl) {
      return (
        <a
          id={linkDomId}
          css={styles.appDescriptionCSSForApp({
            appId: props.appId!,
            dark: headerContext?.dark,
          })}
          href={props.homeUrl}
          data-qa="header-docusign-home-link"
          onClick={(event) => {
            event.preventDefault()
            handleClick()
          }}
        >
          <Image
            src={
              props.appLogoUrl
                ? props.appLogoUrl
                : (OliveImages[imageName!] as OliveImage)
            }
            alt={altText}
            data-qa="header-docusign-logo"
          />
        </a>
      )
    }

    return (
      <div css={styles.appDescriptionNonProductCSS}>
        <DsUiHeader.Logo
          href={props.homeUrl}
          onClick={(event) => {
            event.preventDefault()
            handleClick()
          }}
          text={props.appName}
        />
      </div>
    )

    async function handleClick() {
      if (props.onHomeClick) {
        let waitingForCallback = true
        // 1.) Plan b: if the callback takes too long then don't wait...load page now
        runLater(() => {
          if (waitingForCallback) {
            loadHomePage()
          }
        }, props.maxWaitForOnHomeClickResponse || maximumWaitMsForOnSwitchCallback)
        // 2.) Plan a: invoke callback and then load page
        try {
          // wait for the app to do any work or logging it would like to do
          const doesAppApproveLoad = await props.onHomeClick()
          waitingForCallback = false
          if (doesAppApproveLoad !== false) {
            loadHomePage()
          }
        } catch (err) {
          // whoa...app failed during the callback...load anyway
          waitingForCallback = false
          logCallbackError(err)
          loadHomePage()
        }
      } else {
        loadHomePage()
      }
    }

    function loadHomePage() {
      window.location.href = props.homeUrl
    }

    function logCallbackError(error: Error) {
      props.onLoggingEvent &&
        props.onLoggingEvent({
          isError: true,
          type: 'ERROR',
          description: 'onHomeClick error',
          error,
        })
    }
  }
