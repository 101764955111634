import * as React from 'react'
import {
  Menu,
  Header as DsUiHeader,
  AnyArgsVoidReturn,
  AnchorOrButtonOnClickHandler,
  LinkTarget,
} from '@ds/ui'
import { Locale } from '@ds/base'
import {
  HeaderUserProfile,
  ImageURL,
  ChoiceCallback,
  ProfileChoice,
  HeaderTranslateFunction,
} from '../types'
import { getUserFullName, getUserInitials } from '../utils'
import { HeaderProfileUserContent } from '../Common/HeaderProfileUserContent'

/*

    A menu of profile options which reveals itself from
    a button on the header.  The menu includes information
    about the user and buttons/links (profile choices).

*/

export interface HeaderProfileMenuButtonProps {
  locale: Locale
  translate: HeaderTranslateFunction
  user?: HeaderUserProfile
  profileImage?: ImageURL
  showManageProfile?: boolean
  accountId?: string
  customProfileChoices?: ProfileChoice[]
  isPhone?: boolean
  onLogoff: ChoiceCallback
  onOpenProfileSite?: () => void
  onAccountSwitch?: () => void
}

export const HeaderProfileMenuButton: React.FunctionComponent<HeaderProfileMenuButtonProps> =
  (props) => {
    const menuTriggerButtonRef = React.useRef<AnyArgsVoidReturn>()

    const { user, locale, translate, accountId } = props

    const accessibilityText = [
      user && getUserFullName(user, locale),
      user?.email_address,
      accountId && `${translate('HDR:Account#')} ${accountId}`,
      translate('HDR:profile-menu'),
    ]
      .filter(Boolean)
      .join(', ')

    return (
      <div>
        <Menu.Button>
          {(
            buttonOnClick: AnyArgsVoidReturn,
            buttonOnKeyDown: AnyArgsVoidReturn,
            buttonRef: React.RefObject<HTMLAnchorElement | HTMLButtonElement>,
            menuVisible: boolean,
            menuAnchor: HTMLElement,
            menuRef: React.RefObject<HTMLDivElement>,
            menuItemOnKeyDown: AnyArgsVoidReturn,
            menuItemEventHandler: (
              ...args: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
            ) => AnchorOrButtonOnClickHandler,
            menuOnVisible: () => void
          ) => {
            menuTriggerButtonRef.current = () => {
              buttonOnClick()
            }

            return (
              <>
                <DsUiHeader.Avatar
                  label={accessibilityText}
                  image={props.profileImage}
                  initials={
                    props.user && getUserInitials(props.user, props.locale)
                  }
                  data-qa="header-profile-menu-button"
                  forwardedRef={buttonRef}
                  onClick={menuTriggerButtonRef.current}
                  onKeyDown={buttonOnKeyDown}
                  data-header-id="desktop-show-profile-menu"
                />

                <Menu
                  location="below"
                  alignment="end"
                  visible={menuVisible}
                  anchor={menuAnchor}
                  forwardedRef={menuRef}
                  onVisible={() => {
                    menuOnVisible()
                    notifyProfileMenuOpen(menuRef.current)
                  }}
                  minWidth={false}
                  locationFixed={true}
                >
                  <Menu.Group
                    accessibilityTitle={props.translate(
                      'HDR:profile-actions:A11Y'
                    )}
                  >
                    {renderUserContent(menuItemOnKeyDown)}
                    {renderSeparator()}
                    {renderChoices(menuItemOnKeyDown, menuItemEventHandler)}
                  </Menu.Group>
                </Menu>
              </>
            )
          }}
        </Menu.Button>
      </div>
    )

    function renderSeparator() {
      return (
        <Menu.CustomItem>
          <div
            role="separator"
            aria-hidden="true"
            css={{
              borderBottom: '1px solid rgba(25, 24, 35, 0.15)',
              marginBottom: '8px',
              width: '100%',
            }}
          />
        </Menu.CustomItem>
      )
    }

    function renderChoices(
      menuItemOnKeyDown: AnyArgsVoidReturn,
      menuItemEventHandler: (
        ...args: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
      ) => AnchorOrButtonOnClickHandler
    ) {
      const choices = props.customProfileChoices || []
      return (
        <>
          {props.onAccountSwitch &&
            renderChoice(
              menuItemOnKeyDown,
              menuItemEventHandler,
              'account-switch',
              props.translate('HDR:SwitchAcct'),
              'header-switch-account-button',
              props.onAccountSwitch
            )}
          {choices.map((choice) =>
            renderChoice(
              menuItemOnKeyDown,
              menuItemEventHandler,
              choice.itemId,
              choice.text,
              choice['data-qa'] || `header-choice-${choice.itemId}-button`,
              choice.onClick,
              choice.href,
              choice.rel,
              choice.target
            )
          )}
          {props.onLogoff &&
            renderChoice(
              menuItemOnKeyDown,
              menuItemEventHandler,
              'logoff',
              props.translate('HDR:Logout'),
              'header-logoff-button',
              () => {
                props.onLogoff()
              }
            )}
        </>
      )
    }

    function renderChoice(
      menuItemOnKeyDown: AnyArgsVoidReturn,
      menuItemEventHandler: (
        ...args: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
      ) => AnchorOrButtonOnClickHandler,
      itemId: string,
      text: string,
      qa: string,
      callback?: ChoiceCallback,
      href?: string,
      rel?: string,
      target?: LinkTarget
    ) {
      return (
        <Menu.Item
          key={itemId}
          text={text}
          onClick={menuItemEventHandler(
            (event: Event, closeMenu: () => unknown) => {
              if (callback) {
                if (!href) {
                  event.preventDefault()
                }
                callback()
              }
              closeMenu()
            }
          )}
          href={href}
          rel={rel}
          target={target}
          onKeyDown={callback ? menuItemOnKeyDown : undefined}
          data-qa={qa}
          data-header-id={customChoiceHeaderId(itemId)}
        />
      )
    }

    function renderUserContent(menuItemOnKeyDown: AnyArgsVoidReturn) {
      return (
        <Menu.CustomItem>
          <HeaderProfileUserContent
            {...props}
            manageProfileOnKeyDown={menuItemOnKeyDown}
            onCloseRequest={(action) => {
              if (menuTriggerButtonRef.current) {
                menuTriggerButtonRef.current()
              }
              if (action) {
                action()
              }
            }}
          />
        </Menu.CustomItem>
      )
    }

    function notifyProfileMenuOpen(menuElement: HTMLDivElement | null) {
      if (menuElement) {
        ;(props.customProfileChoices || []).forEach((choice) => {
          if (choice.onShow) {
            const selector = `[data-header-id="${customChoiceHeaderId(
              choice.itemId
            )}"]`
            const element = menuElement.querySelector(selector)
            if (element) {
              choice.onShow(choice.itemId, element as HTMLElement)
            }
          }
        })
      }
    }
  }

function customChoiceHeaderId(choiceId: string) {
  return `custom_choice-${choiceId}`
}
