import colors from '../../theming/docusign-themes/olive/colors'
import type { Tokens } from '../../theming/types'
import type { ShroudProps } from '.'

export default (config: { props?: ShroudProps; tokens: Tokens }) => {
  const { props, tokens } = config

  const Shroud = {
    backgroundColor: tokens.scrim.defaultBackgroundColor,
    bottom: 0,
    display: 'block',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
  } as const

  const light = {
    backgroundColor: colors.white,
  } as const

  const motionVariants = {
    enter: {
      opacity: [0, props?.light ? 0.75 : tokens.scrim.opacityLevel],
      transition: {
        ease: 'opacity',
        duration: 0.4,
      },
    },

    exit: {
      opacity: [props?.light ? 0.75 : tokens.scrim.opacityLevel, 0],
      transition: {
        ease: 'opacity',
        duration: 0.35,
      },
    },
  } as const

  return {
    Shroud,
    light,
    motionVariants,
  }
}
