import type { ModalBaseProps } from '../../../../components/Modal/ModalBase'
import { CreateFocusCss, isPhoneInLandscape } from '../../../../styles'
import type { Tokens } from '../../../types'
import colors from '../colors'

export const ModalBase = (config: {
  props: ModalBaseProps
  tokens: Tokens
}) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        boxShadow: tokens.elevation.highest,
        maxWidth: 'calc(100% - 96px)',
        minHeight: '256px',

        [isPhoneInLandscape]: {
          maxWidth: `calc(100% - ${tokens.modal.marginTopSmallScreen})`,
        },

        // This is an arbitrary media query based on height due to the ample whitespace that Ink provides in order to provide more space to show the entirety of the modal
        '@media screen and (max-height: 400px)': {
          marginTop: tokens.modal.marginTopSmallScreen,
          maxHeight: `calc(100vh - ${tokens.modal.marginTopSmallScreen} * 2)`,
        },
      },

      close: {
        right: '16px',
        top: '16px',

        '> button': {
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          borderRadius: '50%',
        },
      },

      modalBase: {
        minHeight: '254px',
      },
    },
  } as const
}

export const ModalBody = (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      body: {
        minHeight: '100px',
        padding: '8px 48px 0',

        [isPhoneInLandscape]: {
          padding: '8px 32px 0',
        },
      },
    },

    scrollShadow: {
      body: {
        background: `${colors.semanticWhite[100]}`,
        borderBottom: '1px solid rgba(17, 16, 25, 0.10)',
        borderTop: '1px solid rgba(17, 16, 25, 0.10)',
        marginTop: '32px',
        paddingTop: '0 !important', // force override of isPhoneInLandscape styles above

        ...CreateFocusCss(tokens.focus.inner),
      },
    },
  } as const
}

export const ModalFooter = () =>
  ({
    default: {
      wrap: {
        padding: '32px 48px 40px',

        [isPhoneInLandscape]: {
          padding: '32px',
        },
      },
    },

    children: {
      wrap: {
        padding: '16px 48px 40px',

        [isPhoneInLandscape]: {
          display: 'flex',
          flexDirection: 'column',
          padding: '16px 32px 32px',
        },

        '& > button': {
          '&:first-of-type': {
            marginRight: '16px',

            [isPhoneInLandscape]: {
              marginBottom: '8px',
              marginRight: 0,
            },
          },
        },
      },
    },
  } as const)

export const ModalHeader = () =>
  ({
    default: {
      header: {
        padding: '40px 48px 8px',
        minHeight: '56px',

        [isPhoneInLandscape]: {
          padding: '48px 32px 8px',
        },
      },
    },
  } as const)
