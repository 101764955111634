import * as React from 'react'
import { isEmpty } from 'lodash'
import styles from '../styles'
import { commonStyles } from '../../../Common'
import {
  DocusignApp,
  DocusignAppId,
  HeaderOnSwitchCallback,
  HeaderLogHandler,
  HeaderTranslateFunction,
} from '../types'
import { launchUrl, initialFocusDataProp } from '../utils'
import { FlyoutNavItem, AnchorOrButtonOnClickHandler } from '@ds/ui'
import { useTimer } from '@ds/react-utils'

const maximumWaitMsForOnSwitchCallback = 3000
const appSwitchIdPrefix = 'header-app-switch-'

/*

    Renders a list of app links. Current app is styled with active bar.

*/

export interface HeaderAppSwitchListProps {
  appId: DocusignAppId
  translate: HeaderTranslateFunction
  onLoggingEvent: HeaderLogHandler
  switchableApps?: DocusignApp[]
  accountGuid?: string
  onAppSwitch?: HeaderOnSwitchCallback
  maxWaitForOnSwitchResponse?: number
}

export const HeaderAppSwitchList: React.FunctionComponent<HeaderAppSwitchListProps> =
  (props) => {
    const { runLater } = useTimer()
    const apps = props.switchableApps || []

    sortApps(apps)

    return (
      <ul css={styles.appSwitcherListCSS}>
        {!isEmpty(apps) &&
          apps!.map((app, index) => {
            const isCurrentApp = props.appId === app.id
            return (
              <li key={app.id}>
                <AppSwitchLink
                  app={app}
                  isCurrent={isCurrentApp}
                  onClick={(event) => {
                    event.preventDefault()
                    handleAppSwitchClick(app)
                  }}
                  accountGuid={props.accountGuid}
                  focus={index === 0}
                />
                {isCurrentApp && (
                  <span css={commonStyles.visuallyHidden}>
                    {props.translate('selectedNavItem')}
                  </span>
                )}
              </li>
            )
          })}
      </ul>
    )

    async function handleAppSwitchClick(targetApp: DocusignApp) {
      if (props.onAppSwitch) {
        let waitingForCallback = true
        // 1.) Plan b: if the callback takes too long then don't wait...switch now
        runLater(() => {
          if (waitingForCallback) {
            loadApp(targetApp)
          }
        }, props.maxWaitForOnSwitchResponse || maximumWaitMsForOnSwitchCallback)
        // 2.) Plan a: invoke callback and then switch
        try {
          // wait for the app to do any cleanup or logging it would like to do
          const doesAppApproveSwitch = await props.onAppSwitch(targetApp.id)
          waitingForCallback = false
          if (doesAppApproveSwitch !== false) {
            loadApp(targetApp)
          }
        } catch (err) {
          // whoa...app failed during the callback...load anyway
          waitingForCallback = false
          logCallbackError(err)
          loadApp(targetApp)
        }
      } else {
        loadApp(targetApp)
      }
    }

    function loadApp(targetApp: DocusignApp) {
      const targetUrl = launchUrl(targetApp, props.appId, props.accountGuid)
      window.location.href = targetUrl
    }

    function logCallbackError(error: Error) {
      props.onLoggingEvent({
        isError: true,
        type: 'ERROR',
        description: 'onAppSwitch error',
        error,
      })
    }
  }

function sortApps(apps: DocusignApp[]) {
  // eSign should be first, Admin should be last, sort others by id
  apps.sort((appA, appB) => {
    if (appA.id === 'ESign') {
      return -1
    }
    if (appB.id === 'ESign') {
      return 1
    }
    if (appA.id === 'Admin') {
      return 1
    }
    if (appB.id === 'Admin') {
      return -1
    }
    if (appA.id < appB.id) {
      return -1
    }
    if (appA.id > appB.id) {
      return 1
    }
    return 0
  })
}

const AppSwitchLink: React.FunctionComponent<{
  app: DocusignApp
  isCurrent: boolean
  onClick: AnchorOrButtonOnClickHandler
  accountGuid?: string
  focus?: boolean
}> = (props) => {
  const linkRef = React.useRef<HTMLAnchorElement>(null)
  React.useEffect(() => {
    if (linkRef.current) {
      linkRef.current.id = appLinkDomId(props.app.id)
    }
  }, [props.app.id])

  return (
    <FlyoutNavItem
      content={props.app.name}
      selected={props.isCurrent}
      onClick={props.onClick}
      href={launchUrl(props.app, props.app.id, props.accountGuid)}
      data-qa={`${props.app.id}-launch-button`}
      {...initialFocusDataProp(props.focus)}
      forwardedRef={linkRef}
    />
  )
}

function appLinkDomId(appId: DocusignAppId) {
  return `${appSwitchIdPrefix}${appId}`
}
