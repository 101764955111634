import * as React from 'react'
import { MotionPresence, Motion } from '@ds/motion'
import { isEscape, useEventListener, useLockBodyScroll } from '@ds/react-utils'
import { Shroud, trapTabNavigation as trapFocus } from '@ds/ui'
import styles from './styles'
import { RevealFrom, OverflowAttribute } from './types'

const DEFAULT_ANIMATION_MS = 300
type NoArgNoReturn = () => void

export interface RevealProps {
  distance: number
  from?: RevealFrom
  overflow?: OverflowAttribute
  onCancel: () => void
  onShowComplete?: () => void
  onHideComplete?: () => void
  duration?: number
  shrouded?: boolean
  light?: boolean
  visible?: boolean
  trapTabNavigation?: boolean
  focusSelector?: string
  'data-qa'?: string
}

export const Reveal: React.FunctionComponent<RevealProps> = ({
  children,
  distance,
  from = 'left',
  overflow = 'hidden',
  shrouded = true,
  light = false,
  visible = false,
  onCancel,
  onShowComplete,
  onHideComplete,
  duration = DEFAULT_ANIMATION_MS,
  trapTabNavigation = true,
  focusSelector,
  'data-qa': qa = 'reveal',
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const previousFocusRef = React.useRef<HTMLElement>()
  const animateDoneRef = React.useRef<NoArgNoReturn | undefined>(undefined)

  useEventListener(
    'keydown',
    (event) => handleKeydown(event as KeyboardEvent),
    document
  )

  useLockBodyScroll(shrouded && visible)

  const distanceStyle =
    from === 'bottom'
      ? { height: distance }
      : from === 'right'
      ? { width: distance }
      : { width: distance }
  const animateFrom =
    from === 'bottom'
      ? { marginBottom: -distance }
      : from === 'right'
      ? { marginRight: -distance }
      : { marginLeft: -distance }
  const animateEnter =
    from === 'bottom'
      ? { marginBottom: 0 }
      : from === 'right'
      ? { marginRight: 0 }
      : { marginLeft: 0 }
  const animateLeave =
    from === 'bottom'
      ? { marginBottom: -distance }
      : from === 'right'
      ? { marginRight: -distance }
      : { marginLeft: -distance }

  // using pointer approach to done function as Motion does not "render" on exit
  animateDoneRef.current = visible
    ? () => {
        if (trapTabNavigation && containerRef.current) {
          trapFocus(containerRef.current)
          setFocus()
        }
        onShowComplete?.()
      }
    : () => {
        if (
          previousFocusRef.current &&
          document.body.contains(previousFocusRef.current) // make sure it is still on page
        ) {
          previousFocusRef.current.focus()
        }
        onHideComplete?.()
      }

  return (
    <div data-qa={qa}>
      {shrouded && (
        <Shroud
          onClick={() => onCancel()}
          light={light}
          visible={visible}
          data-qa={`${qa}-shroud`}
        />
      )}

      <MotionPresence>
        {visible && (
          <Motion
            as="div"
            ref={containerRef}
            initial={animateFrom}
            animate={animateEnter}
            exit={animateLeave}
            transition={{ duration: duration / 1000 }}
            onAnimationStart={() => {
              if (visible && !previousFocusRef.current) {
                previousFocusRef.current = document.activeElement as HTMLElement
              }
            }}
            onAnimationEnd={() => animateDoneRef.current?.()}
            css={styles.revealContainerCSS(from, overflow, shrouded)}
            style={{ ...distanceStyle }}
          >
            {children}
          </Motion>
        )}
      </MotionPresence>
    </div>
  )

  function handleKeydown(event: KeyboardEvent) {
    if (isEscape(event)) {
      onCancel()
    }
  }

  function setFocus() {
    if (focusSelector && containerRef.current) {
      const focusElement = containerRef.current.querySelector(
        focusSelector
      ) as HTMLElement
      if (focusElement) {
        focusElement.focus()
      }
    }
  }
}
