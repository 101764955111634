import React from 'react'
import { isEmpty, isFunction, upperFirst } from 'lodash'
import {
  getInitials,
  getLocalePolicy,
  SupportedCultureName,
  getFullName,
} from '@ds/i18nlayer'
import { Locale } from '@ds/base'
import {
  HeaderUserProfile,
  DocusignAppId,
  DocusignApp,
  HeaderItem,
  HeaderHelpActions,
} from './types'

export function getUserInitials(user: HeaderUserProfile, locale: Locale) {
  if (user.first_name && user.last_name) {
    const localePolicy = getLocalePolicy(locale as SupportedCultureName)
    return getInitials(
      localePolicy,
      user.first_name || '',
      user.last_name || '',
      false
    )
  }
  return undefined
}

export function getUserFullName(user: HeaderUserProfile, locale: Locale) {
  const localePolicy = getLocalePolicy(locale as SupportedCultureName)
  return getFullName(localePolicy, user.first_name || '', user.last_name || '')
}

export function getAppImageName(appId: DocusignAppId) {
  return 'docusignAppLogo' + upperFirst(appId.toLowerCase())
}

export function getAppLogoAndNameImageName(appId: DocusignAppId) {
  switch (appId) {
    case 'Admin':
      return 'docusignAdmin'
    case 'CLM':
      return 'docusignClm'
    case 'DTR':
      return 'docusignDtr'
    case 'ESign':
      return 'docusignESign'
    case 'Insight':
      return 'docusignInsight'
    default:
      return undefined
  }
}

export function launchUrl(
  targetApp: DocusignApp,
  currentAppId: string,
  accountGuid?: string
) {
  return `${targetApp.launch_uri}${
    targetApp.launch_uri.includes('?') ? '&' : '?'
  }sourceApp=${currentAppId}${accountGuid ? '&accountId=' + accountGuid : ''}`
}

export function shouldDisplayAppSwitcher(
  appId: DocusignAppId,
  switchableApps?: DocusignApp[]
) {
  if (isEmpty(switchableApps)) {
    return false
  }
  if (switchableApps!.length > 1) {
    return true
  }
  return switchableApps![0].id !== appId
}

const DATA_INITIAL_FOCUS = 'data-initial-focus'

export const initialFocusSelector = `[${DATA_INITIAL_FOCUS}="true"]`

export const initialFocusDataProp = (inFocus?: boolean) => {
  return inFocus
    ? {
        [DATA_INITIAL_FOCUS]: true,
      }
    : {}
}

export function isActionElement(element: Element) {
  const tagName = element.tagName.toLowerCase()
  return (
    tagName === 'a' ||
    tagName === 'button' ||
    (tagName === 'input' && element.getAttribute('type') === 'button')
  )
}

export function isActiveTab(headerItem: HeaderItem, activeTabId?: string) {
  if (activeTabId) {
    if (headerItem.itemId === activeTabId) {
      return true
    }
    if (!isEmpty(headerItem.subItems) && Array.isArray(headerItem.subItems)) {
      return (
        (headerItem.subItems as HeaderItem[]).find(
          (subItem) => subItem.itemId === activeTabId
        ) !== undefined
      )
    }
  }
  return false
}

export function isCustomContent(helpActions: HeaderHelpActions) {
  return React.isValidElement(helpActions) || isFunction(helpActions)
}
