import type { Tokens } from '../../../types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      hiddenAvatars: {
        fontSize: tokens.avatar.mediumFontSize,
      },
    },

    link: {
      wrap: {
        '&:hover': {
          span: {
            '& > *': {
              pointerEvents: 'none',
            },
          },
        },
      },
    },
  } as const
}
