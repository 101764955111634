import * as React from 'react'
import { Motion, MotionPresence } from '@ds/motion'
import { Icon, trapTabNavigation } from '@ds/ui'
import {
  isEscape,
  OutsideClickListener,
  useEventListener,
} from '@ds/react-utils'
import OliveImages from '@olive/images'
import styles from '../styles'
import commonStyles from '../../../Common/styles'
import { HeaderAppSwitchList } from '../Common/HeaderAppSwitchList'
import { initialFocusSelector } from '../utils'
import {
  DocusignApp,
  DocusignAppId,
  HeaderLogHandler,
  HeaderOnSwitchCallback,
  HeaderTranslateFunction,
} from '../types'

/*

    A menu of apps which reveals itself from a waffle button
    on the header.

*/

const menuWidth = 240
const defaultAnimationDurationMs = 120
const animationDimensionBeginFactor = 2
const menuBorderTotalWidth = 2

export interface AppSwitcherMenuProps {
  translate: HeaderTranslateFunction
  appId: DocusignAppId
  switchableApps?: DocusignApp[]
  onCloseRequest: () => void
  onCloseComplete: () => void
  visible?: boolean
  /** The duration of the animation in milliseconds */
  animationDuration?: number
  onLoggingEvent: HeaderLogHandler
  onShowAppSwitch?: () => void
  onAppSwitch?: HeaderOnSwitchCallback
}

export const HeaderAppSwitchMenu: React.FunctionComponent<AppSwitcherMenuProps> =
  (props) => {
    const {
      onCloseRequest,
      onCloseComplete,
      visible,
      animationDuration = defaultAnimationDurationMs,
      onShowAppSwitch,
    } = props
    const containerRef = React.useRef<HTMLDivElement>(null)

    useEventListener(
      'keydown',
      (event: Event) => handleKeydown(event as KeyboardEvent),
      document
    )

    React.useEffect(() => {
      if (visible) {
        onShowAppSwitch?.()
      }
    }, [visible, onShowAppSwitch])

    return (
      <MotionPresence>
        {visible && (
          <Motion
            key="appSwitcherMenuContainer"
            ref={containerRef}
            css={styles.appSwitcherMenuContainerCSS}
            initial={{
              opacity: 0,
              width: menuWidth / animationDimensionBeginFactor,
              height: 0,
            }}
            animate={{
              opacity: 1,
              width: menuWidth,
              height: 'auto',
            }}
            exit={{
              opacity: 0,
              width: menuWidth / animationDimensionBeginFactor,
              height: 0,
            }}
            transition={{
              duration: animationDuration / 1000,
            }}
            onAnimationEnd={() => {
              if (visible && containerRef.current) {
                trapTabNavigation(containerRef.current)
                const firstAppElement = containerRef.current.querySelector(
                  initialFocusSelector
                ) as HTMLElement
                if (firstAppElement) {
                  firstAppElement.focus()
                }
              } else if (!visible) {
                onCloseComplete()
              }
            }}
          >
            <MenuContent {...props} />
          </Motion>
        )}
      </MotionPresence>
    )

    function handleKeydown(event: KeyboardEvent) {
      if (isEscape(event)) {
        onCloseRequest()
      }
    }
  }

const MenuContent: React.FunctionComponent<AppSwitcherMenuProps> = (props) => {
  const { translate, switchableApps, onCloseRequest } = props

  return (
    <OutsideClickListener onClickOutside={() => onCloseRequest()}>
      <div css={{ width: menuWidth - menuBorderTotalWidth }}>
        {renderHeading()}
        {renderAppRows()}
      </div>
    </OutsideClickListener>
  )

  function renderHeading() {
    return (
      <>
        <div css={styles.appSwitchHeadingCSS}>
          <button
            type="button"
            css={[
              styles.menuCloseButtonCSS,
              styles.menuCloseFullHeaderButtonCSS,
            ]}
            onClick={(event) => {
              event.preventDefault()
              onCloseRequest()
            }}
            data-qa="header-apps-close-button"
          >
            <Icon kind="close" size={24} />
            <span css={commonStyles.visuallyHidden}>{translate('close')}</span>
          </button>

          <span css={styles.appSwitchLogoWrapper}>
            <img
              width="114"
              height="24"
              src={OliveImages['docusign']}
              alt="DocuSign"
            />
          </span>
        </div>
      </>
    )
  }

  function renderAppRows() {
    return switchableApps && <HeaderAppSwitchList {...props} />
  }
}
