import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from '@ds/comp-private'
import { dataProps } from '@ds/react-utils'
import { SkipNav } from '../../internal/components/SkipNav'
import { useThemeStyles } from '../../theming'
import { AnchorTarget } from '../../variables'
import { IconPosition } from '../../types'
import { HeaderLeft } from './HeaderLeft'
import { HeaderCenter } from './HeaderCenter'
import { HeaderRight } from './HeaderRight'
import { HeaderActionItem } from './HeaderActionItem'
import { HeaderTitle } from './HeaderTitle'
import { HeaderActionItemIcon } from './HeaderActionItemIcon'
import { HeaderAvatar, HeaderAvatarProps } from './HeaderAvatar'
import { HeaderLogo, HeaderLogoProps } from './HeaderLogo'
import { HeaderNav, HeaderNavProps } from './HeaderNav'
import { HeaderContext } from './HeaderContext'
import baseStyles from './styles'

const DEFAULT_SKIP_NAV_TEXT = 'Skip to main content'

export interface HeaderProps {
  /**
   * The content rendered inside the Header.
   */
  children: React.ReactNode
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string

  /**
   * @ignore
   * Apply dark styles to header.
   */
  dark?: boolean

  /**
   * Forces the Header to fill the full width of its container,
   * for those special cases that require it (see Tagger).
   */
  fullWidth?: boolean
  /**
   * The id of the element representing the page's main content.
   * The element with this id will receive focus when the "skip nav" is clicked.
   *
   * The "skip nav" will not display if a target is not provided here.
   */
  skipNavTarget?: string
  /**
   * The text of the "skip nav" that is displayed when a skipNavTarget is provided.
   */
  skipNavText?: string
}

export type HeaderComponent = typeof Header

export type HeaderActionIconPosition = IconPosition

export type HeaderActionTarget = AnchorTarget

export type { HeaderAvatarProps, HeaderLogoProps, HeaderNavProps }

/**
 * This example only exists for historical reference. Please use [@ds/components/Header](https://artifactory.docusignhq.com/artifactory/docusign-public/ds-components/master/demo/index.html#/header).
 */
export function Header(props: HeaderProps) {
  const {
    children,
    dark = false,
    fullWidth = false,
    skipNavTarget = undefined,
    skipNavText = DEFAULT_SKIP_NAV_TEXT,
    ...restProps
  } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'Header', props)

  const translate = useTranslate()

  const headerWrapStyles = [
    styles.Header.wrap,
    fullWidth && styles.Header.fullWidth,
  ]

  const skipNavTextTranslated =
    skipNavText === DEFAULT_SKIP_NAV_TEXT
      ? translate('OLIVE:SKIP_TO_MAIN')
      : skipNavText

  const skipNavTargetId = skipNavTarget && skipNavTarget.replace(/^#+/, '')

  const skipNav = skipNavTargetId && (
    <SkipNav text={skipNavTextTranslated} target={skipNavTargetId} />
  )

  const contextValue = useMemo(
    () => ({
      dark,
    }),
    [dark]
  )

  return (
    <HeaderContext.Provider value={contextValue}>
      {skipNav}
      <header {...dataProps(restProps)} css={styles.Header.default}>
        <div css={headerWrapStyles} className="olv-header olv-ignore-transform">
          {children}
        </div>
      </header>
    </HeaderContext.Provider>
  )
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  dark: PropTypes.bool,
  'data-.*': PropTypes.string,
  fullWidth: PropTypes.bool,
  skipNavTarget: PropTypes.string,
  skipNavText: PropTypes.string,
}

Header.defaultProps = {
  'data-.*': undefined,
  dark: false,
  fullWidth: false,
  skipNavTarget: undefined,
  skipNavText: DEFAULT_SKIP_NAV_TEXT,
}

Header.displayName = 'Header'

Header.Left = HeaderLeft
Header.Center = HeaderCenter
Header.Right = HeaderRight
Header.ActionItem = HeaderActionItem
Header.ActionItemIcon = HeaderActionItemIcon
Header.Avatar = HeaderAvatar
Header.Logo = HeaderLogo
Header.Nav = HeaderNav
Header.Title = HeaderTitle
