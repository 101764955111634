import React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'
import type { EventListenerProps } from '../../../types'

export interface MenuCustomItemProps extends EventListenerProps<HTMLLIElement> {
  /**  The content of the Menu.CustomItem. */
  children: React.ReactNode
  /** Accepts custom data attributes. */
  'data-.*'?: string
  'data-qa'?: string
}

export function MenuCustomItem(props: MenuCustomItemProps) {
  const { children, ...restProps } = props

  return (
    <li {...dataProps(restProps)} {...onProps(restProps)} role="presentation">
      {children}
    </li>
  )
}

MenuCustomItem.displayName = 'Menu.CustomItem'

MenuCustomItem.propTypes = {
  children: PropTypes.node.isRequired,
  'data-.*': PropTypes.string,
  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,
}

MenuCustomItem.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
}
