import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { dataProps, mergeRefs } from '@ds/react-utils'
import { logAlphaProp, noAnchorWarning } from '../../logging'
import { useThemeStyles } from '../../theming'
import { calculateScrollContainerHeight } from '../../utilities'
import { Popper } from '../Popper'
import { MenuAnimation } from './MenuAnimation'
import { MenuUI } from './MenuUI'
import type { MenuProps } from './Menu'
import baseStyles from './styles'

export function MenuWithState(props: MenuProps) {
  const {
    alignment = 'start',
    anchor,
    children,
    forwardedRef,
    location = 'below',
    locationFixed = false,
    maxHeight,
    maxHeightAuto = false,
    minWidth = true,
    positionStatic = false,
    preserveWidth = false,
    visible = false,
    ...restProps
  } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'Menu')

  const animationRef = useRef<HTMLElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (visible && !anchor && !positionStatic) {
      noAnchorWarning('Menu')
    }
  }, [anchor, positionStatic, visible])

  useLayoutEffect(() => {
    // Set the menu's dimensions
    const menuElement = menuRef.current
    if (menuElement && visible) {
      if (maxHeight) {
        menuElement.style.maxHeight = maxHeight
      }

      if (anchor) {
        if (minWidth) {
          menuElement.style.minWidth = `${anchor.offsetWidth}px`
        }

        if (maxHeightAuto) {
          logAlphaProp('Menu', 'maxHeightAuto')
          menuElement.style.maxHeight = calculateScrollContainerHeight(
            anchor,
            menuElement
          )
        }
      }
    }
  }, [anchor, maxHeight, maxHeightAuto, minWidth, visible])

  const positionHelper = [styles.positionHelper, styles.menuWidthHelper]
  const flipModifier = locationFixed ? Popper.presets.flip.disabled : undefined

  return !positionStatic ? (
    <MenuAnimation animationRef={animationRef} visible={!!visible}>
      {/**
       * The menuWidthHelper is required to provide a containing block the Menu
       * content can populate that is the max width of the Menu.  Without this
       * the containing block can be any width and the menu will be constrained.
       */}
      <div css={positionHelper}>
        <Popper
          alignment={alignment}
          anchorElement={anchor}
          location={location}
          onFirstUpdate={restProps.onVisible}
          flipModifier={flipModifier}
        >
          <MenuUI
            {...dataProps(restProps)}
            preserveWidth={preserveWidth}
            forwardedRef={mergeRefs(animationRef, forwardedRef, menuRef)}
          >
            {children}
          </MenuUI>
        </Popper>
      </div>
    </MenuAnimation>
  ) : (
    <MenuAnimation animationRef={animationRef} visible={!!visible}>
      {/* Static is default but being explicit */}
      <div css={{ position: 'static' }}>
        {/* Was position: absolute, which shrunk the div */}
        <div css={{ display: 'inline-block' }}>
          <MenuUI
            {...dataProps(restProps)}
            forwardedRef={mergeRefs(animationRef, forwardedRef, menuRef)}
          >
            {children}
          </MenuUI>
        </div>
      </div>
    </MenuAnimation>
  )
}

MenuWithState.displayName = 'MenuWithState'
