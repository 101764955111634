import React from 'react'
import { dataProps } from '@ds/react-utils'
import { MenuContainer } from './MenuContainer'
import type { MenuProps } from './Menu'

type MenuUIProps = Pick<
  MenuProps,
  'children' | 'data-.*' | 'forwardedRef' | 'preserveWidth'
>

export function MenuUI(props: MenuUIProps) {
  const { children, forwardedRef, preserveWidth = false, ...restProps } = props

  return (
    <MenuContainer
      {...dataProps(restProps)}
      preserveWidth={preserveWidth}
      forwardedRef={forwardedRef}
    >
      {children}
    </MenuContainer>
  )
}

MenuUI.displayName = 'MenuUI'
