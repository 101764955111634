import { styles } from '../../../utilities'
import colors from '../../../theming/docusign-themes/olive/colors'
import type { HeaderTitleProps } from '.'

export default (config: { props?: HeaderTitleProps }) => {
  const { props } = config
  return {
    color: colors.periwinkleLight,
    display: 'block',
    lineHeight: '28px',
    ...styles.ellipsis,

    ...(props?.precededByHeaderAction && {
      borderLeft: `1px solid ${colors.DocuSignBlueLight}`,
      marginLeft: '4px',
      paddingLeft: '16px',
    }),
  } as const
}
