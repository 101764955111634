import type {
  HeaderProps,
  HeaderAvatarProps,
  HeaderTitleProps,
} from '../../../../components/Header'
import { CreateFocusCss } from '../../../../styles'
import type { HeaderContextValue } from '../../../../components/Header/HeaderContext'
import colors from '../colors'

export default (config: { props?: HeaderProps }) => {
  const { props } = config
  return {
    Header: {
      default: {
        backgroundColor: props?.dark
          ? colors.semanticNeutral[100]
          : colors.semanticWhite[100],
      },

      wrap: {
        borderBottom: '1px solid #eaeaea',
        height: '64px',
        padding: '0 4px',
      },
    },

    Nav: {
      '> li': {
        margin: 0,
      },
    },
  } as const
}

export const HeaderTitle = (config: {
  props?: HeaderTitleProps & HeaderContextValue
}) => {
  const { props } = config
  return {
    color: props?.dark
      ? colors.semanticWhite[100]
      : colors.semanticNeutral.fade[60],
    paddingRight: '15px',

    ...(props?.precededByHeaderAction && {
      borderLeftColor: props?.dark
        ? colors.semanticWhite.fade[45]
        : colors.semanticNeutral.fade[45],
    }),
  } as const
}

export const HeaderAvatar = (config: {
  props?: HeaderAvatarProps & HeaderContextValue
}) => {
  const { props } = config
  return {
    wrap: {
      height: '64px',
    },

    button: {
      '&:hover, &:active, &[aria-expanded="true"]': {
        backgroundColor: props?.dark
          ? colors.semanticWhite.fade[25]
          : colors.semanticNeutral.fade[5],
      },

      ...CreateFocusCss({
        outlineColor: props?.dark
          ? colors.semanticWhite[100]
          : colors.semanticNeutral.fade[60],
        outlineStyle: 'solid',
        outlineWidth: '2px',
        outlineOffset: '-4px',
      }),
    },
  } as const
}
