import * as React from 'react'
import type { MenuAlignment } from '@ds/ui'
import { Menu, AnyArgsVoidReturn, AnchorOrButtonOnClickHandler } from '@ds/ui'
import { TranslateFunction, Locale } from '@ds/base'
import { TranslationKeys } from '@ds/comp-private'

import { sortedLocaleAndDisplayName, getDisplayName } from './localeDisplay'
import { FooterMenuTriggerButton } from './FooterMenuTriggerButton'

interface Props {
  alignment?: MenuAlignment
  locales: Locale[]
  currentLocale: Locale
  translate: TranslateFunction<TranslationKeys>
  onLocaleSelected: (locale: Locale) => void
  onMenuOpened?: () => void
  menuButtonDataQa: string
}

export const FooterLanguageSelectorDesktop: React.FunctionComponent<Props> = (
  props
) => {
  const localeDescriptions = sortedLocaleAndDisplayName(props.locales)
  return (
    <div>
      <Menu.Button>
        {(
          buttonOnClick: AnyArgsVoidReturn,
          buttonOnKeyDown: AnyArgsVoidReturn,
          buttonRef: React.RefObject<HTMLAnchorElement | HTMLButtonElement>,
          menuVisible: boolean,
          menuAnchor: HTMLElement,
          menuRef: React.RefObject<HTMLDivElement>,
          menuItemOnKeyDown: AnyArgsVoidReturn,
          menuItemEventHandler: (
            ...args: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
          ) => AnchorOrButtonOnClickHandler,
          menuOnVisible: () => void
        ) => (
          <>
            <FooterMenuTriggerButton
              text={getDisplayName(props.currentLocale)}
              data-qa={props.menuButtonDataQa}
              onClick={buttonOnClick}
              onKeyDown={buttonOnKeyDown}
              forwardedRef={buttonRef as React.RefObject<HTMLButtonElement>}
            />
            <Menu
              location="above"
              alignment={props.alignment || 'start'}
              visible={menuVisible}
              anchor={menuAnchor}
              forwardedRef={menuRef}
              maxHeight="calc(100vh - 80px)"
              locationFixed={true}
              onVisible={() => {
                menuOnVisible()
                props.onMenuOpened && props.onMenuOpened()
              }}
            >
              <Menu.Group
                accessibilityTitle={props.translate('Aria-language-selector')}
              >
                {localeDescriptions.map((langAndName) => {
                  return (
                    <Menu.Item
                      key={langAndName.locale}
                      text={langAndName.displayName}
                      onClick={menuItemEventHandler(
                        (event: Event, closeMenu: () => unknown) => {
                          if (props.currentLocale !== langAndName.locale) {
                            props.onLocaleSelected(langAndName.locale)
                          }
                          closeMenu()
                        }
                      )}
                      onKeyDown={menuItemOnKeyDown}
                      data-qa={`language-selection-${langAndName.locale}`}
                    />
                  )
                })}
              </Menu.Group>
            </Menu>
          </>
        )}
      </Menu.Button>
    </div>
  )
}
