import { CreateFocusCss, isPhoneInLandscape } from '../../../../styles'
import { scrollShadow } from '../../../../utilities'
import type { Tokens } from '../../../types'
import colors from '../colors'

export const ModalBase = (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        boxShadow: tokens.elevation.low,
        maxWidth: 'calc(100% - 48px)',
        minHeight: 0,

        [isPhoneInLandscape]: {
          maxWidth: `calc(100% - ${tokens.modal.marginTopSmallScreen} * 2)`,
        },
      },

      close: {
        right: 0,
        top: 0,
      },

      modalBase: {
        minHeight: '100%',
      },
    },
  } as const
}

export const ModalBody = (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      body: {
        padding: '24px',

        [isPhoneInLandscape]: {
          padding: '10px',
        },
      },
    },

    scrollShadow: {
      body: {
        ...scrollShadow(
          tokens.scrollShadow.background,
          tokens.scrollShadow.color,
          tokens.scrollShadow.height
        ),

        ...CreateFocusCss(tokens.focus.inner),
      },
    },
  } as const
}

export const ModalFooter = () =>
  ({
    default: {
      wrap: {
        borderTop: `1px solid ${colors.gray3}`,
        padding: '12px 24px',

        [isPhoneInLandscape]: {
          padding: '10px',
        },
      },
    },

    children: {
      wrap: {
        [isPhoneInLandscape]: {
          padding: '10px',
        },

        '& > button': {
          '&:first-of-type': {
            marginRight: '10px',
          },
        },
      },
    },
  } as const)

export const ModalHeader = (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      header: {
        borderBottom: `1px solid ${tokens.border.subtleColor}`,
        padding: '20px 24px',

        [isPhoneInLandscape]: {
          padding: '10px',
        },
      },
    },
  } as const
}
