import type { Tokens } from '../../../types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config

  return {
    default: {
      item: {
        '&:last-child a + span': {
          display: 'none',
        },

        '&:last-child button + span': {
          display: 'none',
        },
      },
      text: {
        color: tokens.text.subtleColor,
      },
    },
  } as const
}
