import type { HeaderActionItemProps } from '../../../../components/Header'
import type { HeaderContextValue } from '../../../../components/Header/HeaderContext'
import { CreateFocusCss } from '../../../../styles'
import type { Tokens } from '../../../types'
import colors from '../colors'

export default (config: {
  props?: HeaderActionItemProps & HeaderContextValue
  tokens: Tokens
}) => {
  const { props, tokens } = config

  return {
    default: {
      wrap: {
        color: props?.dark
          ? colors.semanticWhite.fade[70]
          : colors.semanticNeutral.fade[60],
        fontFamily: tokens.font.body,
        fontSize: '16px',
        fontWeight: 600,
        height: '64px',
        letterSpacing: 'normal',
        textTransform: 'initial',
        padding: '0 24px',

        '&::after': {
          bottom: 0,
          height: '3px',
          width: '100%',
        },

        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: props?.dark
            ? colors.semanticWhite.fade[25]
            : colors.semanticNeutral.fade[5],
          color: props?.dark
            ? colors.semanticWhite[100]
            : colors.semanticNeutral.fade[90],

          '&::after': {
            backgroundColor: 'transparent',
          },

          svg: {
            color: props?.dark
              ? colors.semanticWhite[100]
              : colors.semanticNeutral.fade[90],
          },
        },

        ...CreateFocusCss({
          outlineColor: props?.dark
            ? colors.semanticWhite[100]
            : colors.semanticNeutral.fade[60],
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineOffset: '-4px',
        }),
      },

      icon: {
        'svg, img': {
          width: '20px',
          height: '20px',
        },
      },

      badge: {
        right: '15px',
        top: '6px',
      },
    },

    selected: {
      wrap: {
        color: props?.dark
          ? colors.semanticWhite[100]
          : colors.semanticNeutral.fade[90],

        '&::after': {
          backgroundColor: props?.dark
            ? colors.semanticWhite[100]
            : colors.semanticNeutral[100],
        },

        '&:hover, &:active, &[aria-expanded="true"]': {
          color: props?.dark
            ? colors.semanticWhite[100]
            : colors.semanticNeutral.fade[90],

          '&::after': {
            backgroundColor: props?.dark
              ? colors.semanticWhite[100]
              : colors.semanticNeutral[100],
          },
        },
      },
    },

    isIconOnly: {
      badge: {
        right: '7px',
      },
    },

    underlineOnHover: {
      wrap: {
        color: props?.dark
          ? colors.semanticWhite[100]
          : colors.semanticNeutral.fade[60],

        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: props?.dark
            ? colors.semanticWhite[100]
            : colors.semanticNeutral.fade[5],
          color: props?.dark
            ? colors.semanticWhite[100]
            : colors.semanticNeutral.fade[90],

          svg: {
            color: props?.dark
              ? colors.semanticWhite[100]
              : colors.semanticNeutral.fade[90],
          },

          '&::after': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  } as const
}
