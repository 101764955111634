import * as React from 'react'
import { Avatar, FlyoutNavItem, Icon } from '@ds/ui'
import { Locale } from '@ds/base'
import OliveImages from '@olive/images'
import {
  HeaderPhoneMenuState,
  Action,
  HeaderItem,
  LabeledAction,
  DocusignAppId,
  HeaderUserProfile,
  ImageURL,
  DocusignApp,
  HeaderTranslateFunction,
  HeaderLanguageSelector,
  HeaderHelpActions,
} from '../types'
import {
  isActiveTab,
  getUserInitials,
  getUserFullName,
  initialFocusDataProp,
  isCustomContent,
} from '../utils'
import { HeaderPhoneItemRow } from './HeaderPhoneItemRow'
import { HeaderHorizontalSeparator as Separator } from './HeaderHorizontalSeparator'
import styles from '../styles'

import { isEmpty, isFunction } from 'lodash'
import commonStyles from '../../../Common/styles'
import { getDisplayName as getLocaleDisplayName } from '../../Footer/localeDisplay'

/*

    The primary menu for phone view. Displays options header and allows
    navigation to sub-menus

*/

interface HeaderPhoneMainMenuProps {
  translate: HeaderTranslateFunction
  locale: Locale
  appId: DocusignAppId
  appName: string
  activeTabId?: string
  tabItems?: HeaderItem[]
  actionsMenu?: HeaderItem
  helpActions?: HeaderHelpActions
  onNotifications?: Action
  onSettings?: Action
  showNotificationsBadge?: boolean
  showHelpBadge?: boolean
  customLogoUrl?: string
  customLogoAltText?: string
  user?: HeaderUserProfile
  profileImage?: ImageURL
  switchableApps?: DocusignApp[]
  disableProfile?: boolean
  customMobileItems?: HeaderItem[]
  languageSelector?: HeaderLanguageSelector
  showLangSelector?: () => void
  onNavigate: (from: HeaderPhoneMenuState, to: HeaderPhoneMenuState) => void
  onCloseRequest: (action?: () => void) => void
  onBackRequest: () => void
}

export const HeaderPhoneMainMenu: React.FunctionComponent<HeaderPhoneMainMenuProps> =
  (props) => {
    return (
      <>
        {renderHeading()}
        {renderRows()}
      </>
    )

    function renderRows() {
      return (
        <>
          <ul css={styles.phoneMainMenuScrollableRowsCSS}>
            {renderAppSwitcherRow()}
            <ul css={styles.phoneMainMenuItemListCSS}>
              {renderAppLocalRows()}
              {renderHeaderMenuButtonRows()}
            </ul>
          </ul>
          {!props.disableProfile && renderProfileRow()}
        </>
      )
    }

    function renderHeading() {
      return (
        <>
          <div css={styles.menuHeadingCenteredCSS}>
            <button
              type="button"
              css={styles.menuCloseButtonCSS}
              onClick={(event) => {
                event.preventDefault()
                props.onCloseRequest()
              }}
              aria-expanded="true"
              data-qa="header-phone-menu-close-button"
            >
              <Icon kind="close" size={24} />
              <span css={commonStyles.visuallyHidden}>
                {props.translate('HDR:show-site-nav')}
              </span>
            </button>
            <span css={styles.appSwitchLogoWrapper} data-qa="header-logo">
              <img
                width="114"
                height="24"
                src={OliveImages['docusign']}
                alt="DocuSign"
              />
            </span>
          </div>
        </>
      )
    }

    function renderAppSwitcherRow() {
      return (
        !isEmpty(switchableOtherApps()) && (
          <li>
            <FlyoutNavItem
              content={props.appName}
              onClick={(event) => {
                event.preventDefault()
                navigateToAppSwitcher()
              }}
              disclosure={<FlyoutNavItem.Disclosure kind="caretRight" />}
              aria-haspopup="true"
              {...initialFocusDataProp(true)}
              data-qa="header-phone-apps-button"
            />
          </li>
        )
      )
    }

    function renderAppLocalRows() {
      let topTabs = isEmpty(props.tabItems) ? [] : [...props.tabItems!]
      // handle the Actions menu as just another tab item
      if (props.actionsMenu) {
        topTabs = [...topTabs, props.actionsMenu]
      }
      if (topTabs.length > 0) {
        return (
          <>
            {topTabs.map((tabItem, index) => {
              const isActive = isActiveTab(tabItem, props.activeTabId)
              const isInitialFocus =
                index === 0 && isEmpty(switchableOtherApps())
              return (
                <li key={tabItem.itemId}>
                  <HeaderPhoneItemRow
                    translate={props.translate}
                    key={tabItem.itemId}
                    text={tabItem.text}
                    hasSubChoices={!isEmpty(tabItem.subItems)}
                    rowId={tabItem.itemId}
                    onClick={(event) => {
                      if (tabItem.preventDefault) {
                        event.preventDefault()
                      }
                      handleHeaderItemClick(tabItem)
                    }}
                    href={tabItem.href}
                    rel={tabItem.rel}
                    target={tabItem.target}
                    isActive={isActive}
                    activeItemAccessabilityText={
                      isActive ? props.translate('selectedNavItem') : undefined
                    }
                    showBadge={tabItem.showBadge}
                    initialFocus={isInitialFocus}
                    data-qa={
                      tabItem['data-qa'] ||
                      'header-' + tabItem.itemId + '-tab-button'
                    }
                  />
                  {index === props.tabItems!.length - 1 ||
                  index === topTabs.length - 1 ? (
                    <Separator />
                  ) : null}
                </li>
              )
            })}
          </>
        )
      }
      return ''
    }

    function renderHeaderMenuButtonRows() {
      return (
        <>
          {renderNotificationsRow()}
          {renderSettingsRow()}
          {renderHelpRow()}
          {renderLanguageRow()}
          {renderCustomMobileItems()}
        </>
      )
    }

    function renderNotificationsRow() {
      return (
        props.onNotifications && (
          <HeaderPhoneItemRow
            translate={props.translate}
            key="notifications"
            text={props.translate('HDR:Notifications')}
            hasSubChoices={false}
            rowId="nofifications"
            onClick={() => {
              handleNotificationsClick()
            }}
            href={props.onNotifications.href}
            rel={props.onNotifications.rel}
            target={props.onNotifications.target}
            isActive={props.activeTabId === 'NOTIFICATIONS'}
            showBadge={props.showNotificationsBadge}
            data-qa="header-notifications-button"
          />
        )
      )
    }

    function renderSettingsRow() {
      return (
        props.onSettings && (
          <HeaderPhoneItemRow
            translate={props.translate}
            key="settings"
            text="Settings" // TODO: https://jira.corp.docusign.com/browse/FRNTEND-3510
            hasSubChoices={false}
            rowId="settings"
            onClick={() => {
              handleSettingsClick()
            }}
            href={props.onSettings.href}
            rel={props.onSettings.rel}
            target={props.onSettings.target}
            isActive={props.activeTabId === 'SETTINGS'}
            data-qa="header-settings-button"
          />
        )
      )
    }

    function renderCustomMobileItems() {
      return (
        !isEmpty(props.customMobileItems) && (
          <>
            {props.customMobileItems!.map((rootItem) => (
              <li key={rootItem.itemId}>
                <HeaderPhoneItemRow
                  translate={props.translate}
                  key={rootItem.itemId}
                  text={rootItem.text}
                  hasSubChoices={!isEmpty(rootItem.subItems)}
                  rowId={rootItem.itemId}
                  onClick={() => handleHeaderItemClick(rootItem)}
                  isActive={false}
                  data-qa={rootItem['data-qa'] || `header-${rootItem.itemId}`}
                />
              </li>
            ))}
          </>
        )
      )
    }

    function renderLanguageRow() {
      if (props.languageSelector) {
        const currentLocaleDescription = getLocaleDisplayName(props.locale)
        return (
          <li>
            <HeaderPhoneItemRow
              translate={props.translate}
              key="MOBILE_LANG_SELECTOR"
              text={currentLocaleDescription}
              hasSubChoices={false}
              rowId="MOBILE_LANG_SELECTOR"
              onClick={handleLanguageClick}
              isActive={false}
              data-qa="header-language-button"
            />
          </li>
        )
      }
      return null
    }

    function renderHelpRow() {
      const helpRootItem =
        isEmpty(props.helpActions) && !isFunction(props.helpActions)
          ? undefined
          : createHelpHeaderRootItem()
      return (
        helpRootItem && (
          <li>
            <HeaderPhoneItemRow
              translate={props.translate}
              key={helpRootItem.itemId}
              text={helpRootItem.text}
              hasSubChoices={
                !isEmpty(helpRootItem.subItems) ||
                isFunction(helpRootItem.subItems)
              }
              rowId={helpRootItem.itemId}
              onClick={() => handleHeaderItemClick(helpRootItem)}
              href={helpRootItem.href}
              rel={helpRootItem.rel}
              target={helpRootItem.target}
              isActive={false}
              showBadge={props.showHelpBadge}
              data-qa="header-help-button"
            />
          </li>
        )
      )
    }

    function renderProfileRow() {
      const user = props.user
      const userName = user
        ? getUserFullName(user, props.locale)
        : props.translate('HDR:Profile')
      return (
        <div css={{ borderTop: '1px solid #d0d0d0' }}>
          <FlyoutNavItem
            graphic={
              <Avatar
                image={props.profileImage}
                initials={user && getUserInitials(user, props.locale)}
                size="small"
              />
            }
            content={
              <div css={{ display: 'flex', flexDirection: 'column' }}>
                {props.customLogoUrl ? (
                  <div>
                    <img
                      css={styles.phoneCustomLogoCSS}
                      src={props.customLogoUrl}
                      alt={
                        props.customLogoAltText ||
                        props.translate('HDR:CustomLogoAltText:A11Y')
                      }
                      data-qa="header-custom-logo"
                    />
                  </div>
                ) : null}
                <div css={styles.phoneProfileRowTextCSS}>{userName}</div>
              </div>
            }
            disclosure={<FlyoutNavItem.Disclosure kind="caretRight" />}
            data-qa="header-phone-profile-button"
            onClick={(event) => {
              event.preventDefault()
              navigateToProfile()
            }}
          />
        </div>
      )
    }

    function createHelpHeaderRootItem(): HeaderItem {
      const helpActions = props.helpActions!
      const rootItem: HeaderItem = {
        itemId: 'HELP',
        text: props.translate('HDR:Help'),
      }
      const customContent = isCustomContent(helpActions)
      const isSingleAction =
        !customContent && (helpActions as LabeledAction[]).length === 1
      if (isSingleAction) {
        rootItem.onClick = () => helpActions[0].onClick()
      } else if (customContent) {
        rootItem.subItems = helpActions
      } else {
        const items: HeaderItem[] = (helpActions as LabeledAction[]).map(
          (helpAction) => {
            return {
              itemId: helpAction.itemId,
              text: helpAction.text,
              onClick: helpAction.onClick,
              href: helpAction.href,
            }
          }
        )
        rootItem.subItems = items
      }
      return rootItem
    }

    function handleHeaderItemClick(tabItem: HeaderItem) {
      if (isEmpty(tabItem.subItems) && !isFunction(tabItem.subItems)) {
        props.onCloseRequest(() => {
          if (tabItem.onClick) {
            tabItem.onClick()
          }
        })
      } else {
        navigateToTabItem(tabItem)
      }
    }

    function handleNotificationsClick() {
      props.onCloseRequest(() => {
        if (props.onNotifications && props.onNotifications.onClick) {
          props.onNotifications.onClick()
        }
      })
    }

    function handleSettingsClick() {
      props.onCloseRequest(() => {
        if (props.onSettings && props.onSettings.onClick) {
          props.onSettings.onClick()
        }
      })
    }

    function handleLanguageClick() {
      props.onCloseRequest(() => {
        props.showLangSelector?.()
      })
    }

    function createMainMenuState(): HeaderPhoneMenuState {
      return { type: 'main' }
    }

    function navigateToAppSwitcher() {
      props.onNavigate(createMainMenuState(), {
        type: 'appswitch',
      })
    }

    function navigateToProfile() {
      props.onNavigate(createMainMenuState(), {
        type: 'profile',
      })
    }

    function navigateToTabItem(tabItem: HeaderItem) {
      props.onNavigate(createMainMenuState(), {
        type: 'tab',
        item: tabItem,
      })
    }

    function switchableOtherApps() {
      const apps = props.switchableApps || []
      return apps.filter((app) => app.id !== props.appId)
    }
  }
