import type { InputTextBoxProps } from '../../../../components/InputTextBox'
import { breakpoints } from '../../../../utilities'
import type { Tokens } from '../../../types'

export default (config: { props: InputTextBoxProps; tokens: Tokens }) => {
  const { props, tokens } = config

  return {
    default: {
      wrapper: {
        maxHeight: '48px',
      },

      input: {
        paddingTop: '7px',
        paddingBottom: '7px',
        paddingLeft: props.leftElement ? 0 : '16px',
        paddingRight: props.rightElement ? 0 : '16px',

        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          minHeight: tokens.button.mobileSize,
        },
      },
    },
  } as const
}
