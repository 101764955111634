import * as React from 'react'
import {
  Header as DsUiHeader,
  Icon,
  Menu,
  AnyArgsVoidReturn,
  AnchorOrButtonOnClickHandler,
} from '@ds/ui'
import { HeaderItem, HeaderTranslateFunction } from '../types'
import { isActiveTab } from '../utils'

/*

    A tab which has sub tabs.  When the tab is clicked it will open a menu of sub items.
    If a subitem of the tab is selected the tab will show as selected.

*/

export interface HeaderTabMenuProps {
  headerItem: HeaderItem
  translate: HeaderTranslateFunction
  activeTabId?: string
}

export const HeaderTabMenu: React.FunctionComponent<HeaderTabMenuProps> = (
  props
) => {
  return (
    <Menu.Button>
      {(
        buttonOnClick: AnyArgsVoidReturn,
        buttonOnKeyDown: AnyArgsVoidReturn,
        buttonRef: React.RefObject<HTMLAnchorElement | HTMLButtonElement>,
        menuVisible: boolean,
        menuAnchor: HTMLElement,
        menuRef: React.RefObject<HTMLDivElement>,
        menuItemOnKeyDown: AnyArgsVoidReturn,
        menuItemEventHandler: (
          ...args: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
        ) => AnchorOrButtonOnClickHandler,
        menuOnVisible: () => void
      ) => (
        <>
          <DsUiHeader.ActionItem
            text={props.headerItem.text}
            onClick={buttonOnClick}
            forwardedRef={buttonRef}
            onKeyDown={buttonOnKeyDown}
            selected={isActiveTab(props.headerItem, props.activeTabId)}
            iconElement={<Icon kind="caretDown" size={20} />}
            iconPosition="afterText"
            data-qa={
              props.headerItem['data-qa'] ||
              'header-' + props.headerItem.itemId + '-tab-button'
            }
          />

          <Menu
            location="below"
            alignment="start"
            visible={menuVisible}
            anchor={menuAnchor}
            forwardedRef={menuRef}
            onVisible={menuOnVisible}
            minWidth={false}
            locationFixed={true}
            maxHeight="calc(100vh - 100px)"
          >
            {renderSubItems(menuItemOnKeyDown, menuItemEventHandler)}
          </Menu>
        </>
      )}
    </Menu.Button>
  )

  function renderSubItems(
    menuItemOnKeyDown: AnyArgsVoidReturn,
    menuItemEventHandler: (
      ...args: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
    ) => AnchorOrButtonOnClickHandler
  ) {
    const subItems = (props.headerItem.subItems as HeaderItem[]) || []
    return (
      <Menu.Group
        accessibilityTitle={
          props.headerItem.text +
          props.translate('subject-menu', {
            SUBJECT: props.headerItem.text,
          })
        }
      >
        {subItems
          .filter((subItem) => !subItem.mobileOnly)
          .map((subItem) =>
            renderSubItem(menuItemOnKeyDown, menuItemEventHandler, subItem)
          )}
      </Menu.Group>
    )
  }

  function renderSubItem(
    menuItemOnKeyDown: AnyArgsVoidReturn,
    menuItemEventHandler: (
      ...args: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
    ) => AnchorOrButtonOnClickHandler,
    subItem: HeaderItem
  ) {
    return (
      <Menu.Item
        key={subItem.itemId}
        text={subItem.text}
        selected={subItem.itemId === props.activeTabId}
        onClick={
          subItem.onClick
            ? menuItemEventHandler((event: Event, closeMenu: () => unknown) => {
                event.preventDefault()
                subItem.onClick?.()
                closeMenu()
              })
            : undefined
        }
        href={subItem.href}
        rel={subItem.rel}
        target={subItem.target}
        onKeyDown={menuItemOnKeyDown}
        data-qa={
          subItem['data-qa'] || 'header-' + subItem.itemId + '-tab-button'
        }
      />
    )
  }
}
