import { keyframes } from '@emotion/core'
import type { Tokens } from '../../theming/types'
import { CreateButtonCss, CreateFocusCss } from '../../styles'
import colors from '../../theming/docusign-themes/olive/colors'
import fontFamilies from '../../theming/docusign-themes/olive/fontFamilies'
import { styles } from '../../utilities'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    Button: {
      default: {
        ...tokens.typography.button,
        alignItems: 'center',
        borderRadius: '2px',
        borderColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: '1px',
        color: colors.black,
        cursor: 'pointer',
        display: 'inline-flex',
        fontFamily: fontFamilies.MavenPro,
        justifyContent: 'center',
        letterSpacing: '0.66px',
        margin: 0,
        minHeight: tokens.button.defaultSize,
        overflow: 'visible',
        padding: '4px 14px',
        position: 'relative',
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: 'uppercase',
        transitionProperty: 'background-color, border-color, box-shadow, color',
        transitionDuration: '0.1s',
        transitionTimingFunction: 'cubic-bezier(0.33, 0, 0.67, 1)',
        verticalAlign: 'middle',

        '&:lang(ja), &:lang(ko), &:lang(zh)': {
          whiteSpace: 'nowrap',
        },

        '& img': {
          height: '16px',
        },

        '& svg': {
          fill: 'currentColor',
          transition: 'fill 0.2s ease',
        },

        '&:hover': {
          svg: {
            fill: 'currentcolor',
          },
        },

        ...CreateFocusCss(tokens.focus.outer),

        '&:disabled, &:disabled:hover': {
          cursor: 'not-allowed',
        },
      },

      disabled: {
        '&:hover': {
          cursor: 'not-allowed',
        },
      },

      groupLeft: {
        borderRadius: '2px 0px 0px 2px',
        borderRight: 'none',

        ...CreateFocusCss({
          zIndex: 1,
        }),
      },

      groupMiddle: {
        borderRadius: 0,
        borderRight: 'none',

        ...CreateFocusCss({
          zIndex: 1,
        }),
      },

      groupRight: {
        borderRadius: '0px 2px 2px 0px',

        ...CreateFocusCss({
          zIndex: 1,
        }),
      },

      main: CreateButtonCss(tokens.button.main),

      primary: CreateButtonCss(tokens.button.primary),

      secondary: CreateButtonCss(tokens.button.secondary),

      tertiary: CreateButtonCss(tokens.button.tertiary),

      danger: CreateButtonCss(tokens.button.danger),

      inverted: {
        ...CreateFocusCss({
          outlineColor: colors.white,
        }),

        primary: {
          backgroundColor: colors.white,
          borderColor: colors.white,
          color: colors.black,

          '& svg': {
            fill: colors.black,
          },

          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: colors.white,
            color: colors.white,

            '& svg': {
              fill: colors.white,
            },
          },

          '&:active': {
            opacity: 0.7,
          },

          '&:disabled, &:disabled:hover': {
            opacity: 0.3,
          },
        },

        secondary: {
          backgroundColor: 'transparent',
          borderColor: colors.white,
          color: colors.white,

          '& svg': {
            fill: colors.white,
          },

          '&:hover': {
            backgroundColor: colors.white,
            color: colors.black,

            '& svg': {
              fill: colors.gray8,
            },
          },

          '&:active': {
            opacity: 0.7,
          },

          '&:disabled, &:disabled:hover': {
            opacity: 0.4,
          },
        },

        tertiary: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          color: colors.white,

          '& svg': {
            fill: colors.white,
          },

          '&:hover': {
            borderColor: colors.white,
            color: colors.white,
            transition: `
            background-color 0.2s ease,
            border 0.2s ease,
            box-shadow 0.2s ease,
            color 0.2s ease
          `,

            '& svg': {
              fill: colors.white,
            },
          },

          '&:active, &[aria-expanded="true"]': {
            opacity: 0.7,
          },

          '&:disabled, &:disabled:hover': {
            opacity: 0.4,
          },
        },
      },

      sizeSmall: {
        fontSize: '11px',
        minHeight: '22px',
        minWidth: '22px',
        padding: '1px 10px',
      },

      sizeMedium: {
        fontSize: '12px',
        minHeight: tokens.button.defaultSize,
        minWidth: tokens.button.defaultSize,
        padding: '4px 14px',
      },

      sizeLarge: {
        fontSize: '13px',
        minHeight: '34px',
        minWidth: '34px',
        padding: '7px 18px',
      },

      sizeXlarge: {
        fontSize: '13px',
        minHeight: '40px',
        minWidth: '40px',
        padding: '10px 20px',
      },

      round: {
        borderRadius: '50%',
        boxShadow: tokens.elevation.low,
        height: '48px',
        minHeight: '48px',
        padding: 0,
        width: '48px',

        '&:hover': {
          boxShadow: tokens.elevation.medium,
        },
      },

      fullWidth: {
        width: '100%',
      },

      text: {
        pointerEvents: 'none',
      },
    },

    Icon: {
      default: {
        lineHeight: '0.66px',
        pointerEvents: 'none',
      },

      beforeText: {
        marginLeft: '-4px',
        marginRight: '6px',
      },

      afterText: {
        marginLeft: '6px',
        marginRight: '-4px',
      },

      round: {
        margin: 0,
      },

      hideText: {
        margin: 0,
      },
    },

    menuTrigger: {
      paddingRight: '6px',
    },

    caret: {
      display: 'inline-flex',
      marginLeft: '2px',
    },

    /**
     *  1. We set height: 0 here to address a flex bug in IE11.
     *    Since we are always setting a min-height on buttons, this should not
     *    have any effect on the button itself.
     *    This is not an ideal solution and is intended as a temporary fix.
     */
    hideText: {
      height: 0 /* 1 */,
      padding: 0,
    },

    pill: {
      borderRadius: '20px',
    },

    hidden: styles.visuallyHidden,

    fileInput: {
      input: {
        ...styles.visuallyHidden,

        '&:focus + label': tokens.focus.outer,
      },
    },

    loadingSpinner: {
      animation: `${spin} 1s infinite linear`,
      borderRadius: '100%',
      borderStyle: 'solid',
      borderTopColor: 'transparent',
      borderWidth: '2px',
      display: 'inline-block',
      height: '14px',
      marginRight: '8px',
      verticalAlign: 'middle',
      width: '14px',
    },
  } as const
}
