import * as React from 'react'
import { Header as DsUiHeader, DotBadge } from '@ds/ui'
import { HeaderItem } from '../types'

/*

    A tab which does NOT have sub tabs.  Button or anchor.
    Supports a selected state.

*/

interface HeaderTabProps {
  tabItem: HeaderItem
  selected?: boolean
}

export const HeaderTab: React.FunctionComponent<HeaderTabProps> = ({
  tabItem,
  selected,
}) => {
  return (
    <DsUiHeader.ActionItem
      text={tabItem.text}
      href={tabItem.href}
      rel={tabItem.rel}
      target={tabItem.target}
      onClick={
        tabItem.onClick || tabItem.preventDefault
          ? (event: React.MouseEvent<HTMLElement>) => {
              if (tabItem.preventDefault) {
                event.preventDefault()
              }
              if (tabItem.onClick) {
                handleItemClick(tabItem, event.target! as HTMLElement)
              }
            }
          : undefined
      }
      selected={selected}
      data-qa={tabItem['data-qa'] || 'header-' + tabItem.itemId + '-tab-button'}
      badge={tabItem.showBadge ? <DotBadge color="red" /> : undefined}
    />
  )

  function handleItemClick(item: HeaderItem, element: HTMLElement) {
    tabItem.onClick!(element)
  }
}
