import hexToRgba from 'hex-to-rgba'
import type { HeaderTitleProps } from '../../../components/Header/HeaderTitle'
import { CreateFocusCss } from '../../../styles'
import type { CobrandingTokens } from '../../types'

export default (config: { tokens: CobrandingTokens }) => {
  const { tokens } = config
  return (
    tokens.headerBackgroundColor &&
    ({
      Header: {
        default: {
          backgroundColor: tokens.headerBackgroundColor,
        },
      },
    } as const)
  )
}

export const HeaderTitle = (config: {
  props?: HeaderTitleProps
  tokens: CobrandingTokens
}) => {
  const { props, tokens } = config
  return {
    color: tokens.headerTextColor,

    ...(props?.precededByHeaderAction && {
      borderLeftColor: tokens.headerTextColor
        ? hexToRgba(tokens.headerTextColor, 0.3)
        : null,
    }),
  } as const
}

export const HeaderAvatar = (config: { tokens: CobrandingTokens }) => {
  const { tokens } = config
  return {
    button: {
      color: tokens.headerTextColor,

      '&:hover': {
        backgroundColor: tokens.headerBackgroundHoverColor,
        color: tokens.headerTextColor,
      },

      ...CreateFocusCss({ outlineColor: 'currentColor' }),
    },
  } as const
}
