import React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'
import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'
import type {
  ButtonForwardRef,
  ElementForwardRef,
  EventListenerProps,
} from '../../../types'
import { Avatar } from '../../Avatar'
import baseStyles from './styles'
import { useHeaderContext } from '../HeaderContext'

export interface HeaderAvatarProps
  extends EventListenerProps<HTMLButtonElement> {
  /**
   * Role of the Header.Avatar component
   * (the label does not display, required for accessibility).
   */
  label: string // for accessibility only...not displayed
  /**
   * If URL for an image is supplied, it will take the highest priority and be displayed.
   */
  image?: string
  /**
   * Initials will take first two letter of the initials string supplied.
   */
  initials?: string
  /**
   * A React ref to assign to the HTML node representing the Header.Avatar element.
   */
  forwardedRef?: ElementForwardRef
  /**
   * @deprecated Use forwardedRef instead
   */
  ref?: ElementForwardRef
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

export function HeaderAvatar(props: HeaderAvatarProps) {
  const {
    forwardedRef,
    label,
    image = '',
    initials = '',
    onClick,
    ...restProps
  } = props

  const headerContext = useHeaderContext()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'HeaderAvatar', {
    ...props,
    ...headerContext,
  })

  return (
    <div css={styles.wrap}>
      <button
        {...dataProps(restProps)}
        {...onProps(restProps)}
        type="button"
        css={styles.button}
        onClick={onClick}
        ref={forwardedRef as ButtonForwardRef}
      >
        <Avatar image={image} initials={initials} size="medium" hideAriaLabel />

        <span css={styles.label}>{label}</span>
      </button>
    </div>
  )
}

HeaderAvatar.propTypes = {
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  image: PropTypes.string,
  initials: PropTypes.string,
  label: PropTypes.string.isRequired,
  'on[A-Z].*': PropTypes.func,
  onClick: PropTypes.func,
}

HeaderAvatar.defaultProps = {
  image: '',
  initials: '',
}

HeaderAvatar.displayName = 'Header.Avatar'
