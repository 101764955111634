import React from 'react'
import { SystemIconKey } from '@ds/icons'
import PropTypes from 'prop-types'
import { IconSmall } from '../../../internal/components/IconSmall'

export const HeaderActionItemIcons = IconSmall.kinds

export interface HeaderActionItemIconProps {
  /**
   * The Icon to display.
   */
  kind: SystemIconKey
}

export function HeaderActionItemIcon(props: HeaderActionItemIconProps) {
  const { kind } = props

  return <IconSmall kind={kind} />
}

HeaderActionItemIcon.icons = HeaderActionItemIcons

HeaderActionItemIcon.propTypes = {
  kind: PropTypes.string.isRequired,
}

HeaderActionItemIcon.displayName = 'Header.ActionItemIcon'
