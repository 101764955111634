import * as React from 'react'
import { Button, Avatar, AnyArgsVoidReturn } from '@ds/ui'
import { isEmpty } from 'lodash'
import { Locale } from '@ds/base'
import { HeaderUserProfile, ImageURL, HeaderTranslateFunction } from '../types'
import {
  getUserFullName,
  getUserInitials,
  initialFocusDataProp,
} from '../utils'
import styles from '../styles'

/*

    Renders user profile (name, account id, email) and a button for me-site

*/

interface HeaderProfileUserContentProps {
  locale: Locale
  translate: HeaderTranslateFunction
  user?: HeaderUserProfile
  profileImage?: ImageURL
  accountId?: string
  showManageProfile?: boolean
  onOpenProfileSite?: () => void
  onCloseRequest: (action?: () => void) => void
  isPhone?: boolean
  profileExtraInfo?: React.ReactNode[]
  manageProfileOnKeyDown: AnyArgsVoidReturn
}

export const HeaderProfileUserContent: React.FunctionComponent<HeaderProfileUserContentProps> =
  (props) => {
    const translate = props.translate
    const user = props.user

    return (
      <div
        css={
          props.isPhone ? styles.phoneProfileUserCSS : styles.menuProfileUserCSS
        }
      >
        <span aria-hidden="true">
          {renderUserInfo()}
          {props.accountId && (
            <div css={styles.phoneProfileUserDetailCSS}>
              {translate('HDR:Account#')}
              <span data-qa="header-profile-accountid" title={props.accountId}>
                {props.accountId}
              </span>
            </div>
          )}
        </span>
        {renderProfileExtraInfo()}
        {renderProfileButton()}
      </div>
    )

    function renderUserInfo() {
      return (
        (user && (
          <>
            {props.isPhone && (
              <Avatar
                image={props.profileImage}
                initials={user && getUserInitials(user, props.locale)}
              />
            )}
            <div
              css={styles.phoneProfileUserNameCSS}
              data-qa="header-profile-user-name"
              title={getUserFullName(user, props.locale)}
            >
              {getUserFullName(user, props.locale)}
            </div>
            <div
              css={styles.phoneProfileUserDetailCSS}
              data-qa="header-profile-user-email"
              title={user.email_address}
            >
              {user.email_address}
            </div>
          </>
        )) ||
        null
      )
    }

    function renderProfileButton() {
      return (
        props.showManageProfile &&
        props.user && (
          <div css={styles.phoneProfileButtonCSS}>
            <Button
              kind="secondary"
              size="small"
              text={translate('HDR:ManageProfile')}
              data-qa="header-phone-manage-profile-button"
              onKeyDown={props.manageProfileOnKeyDown}
              onClick={() =>
                props.onCloseRequest(() => handleProfileSiteNavigation())
              }
              role="menuitem"
              {...initialFocusDataProp(props.isPhone)}
            />
          </div>
        )
      )
    }

    function renderProfileExtraInfo() {
      if (!isEmpty(props.profileExtraInfo)) {
        return props.profileExtraInfo!.map((extraInfo, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            css={styles.phoneProfileUserDetailCSS}
            data-qa={`header-profile-extra-info-${index}`}
          >
            {extraInfo}
          </div>
        ))
      }
      return null
    }

    function handleProfileSiteNavigation() {
      props.onOpenProfileSite?.()
      props.onCloseRequest(() => {
        window.open(props.user!.me_site_uri, 'DocusignUserProfile')
      })
    }
  }
