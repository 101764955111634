import React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { useThemeStyles } from '../../../theming'
import { useHeaderContext } from '../HeaderContext'
import baseStyles from './styles'

export interface HeaderTitleProps {
  /**
   * Text that will be rendered for the HeaderTitle element.
   */
  children: string

  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string

  /** @ignore */
  precededByHeaderAction?: boolean
}

export function HeaderTitle(props: HeaderTitleProps) {
  const { children, ...restProps } = props

  const headerContext = useHeaderContext()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'HeaderTitle', {
    ...props,
    ...headerContext,
  })

  return (
    <p {...dataProps(restProps)} css={styles}>
      {children}
    </p>
  )
}

HeaderTitle.propTypes = {
  children: PropTypes.string.isRequired,
  'data-.*': PropTypes.string,
  precededByHeaderAction: PropTypes.bool,
}

HeaderTitle.defaultProps = {
  'data-.*': undefined,
  precededByHeaderAction: false,
}

HeaderTitle.displayName = 'Header.Title'
