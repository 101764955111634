import React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { useThemeStyles } from '../../theming'
import baseStyles from './styles'

export interface HeaderNavProps {
  /**
   * The normal usage pattern is:
   *
   * ```
   * <Header.Nav>
   *   <Header.ActionItem />
   *   <Header.ActionItem />
   *   // any number of Header.ActionItem components
   * </Header.Nav>
   * ```
   *
   * If you are using a routing library you can wrap
   * `Header.ActionItem` with a routing component from that library,
   * without passing `href` or `onClick` to `Header.ActionItem`.
   *
   * e.g. using `react-router`:
   *
   * ```
   * import { Link } from 'react-router-dom'
   *
   * <Header.Nav>
   *   <Link to={`${matchUrl}/documents`}> <Header.ActionItem text="Documents" /> </Link>
   *   <Link to={`${matchUrl}/tasks`}> <Header.ActionItem text="Tasks" /> </Link>
   * </Header.Nav>
   * ```
   */
  children: React.ReactNode
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

export function HeaderNav(props: HeaderNavProps) {
  const { children, ...restProps } = props

  const HeaderNavItems = React.Children.map(children, (child) => (
    <li>{child}</li>
  ))

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'Header')

  return (
    <ul {...dataProps(restProps)} css={styles.Nav}>
      {HeaderNavItems}
    </ul>
  )
}

HeaderNav.propTypes = {
  children: PropTypes.node.isRequired,
  'data-.*': PropTypes.string,
}

HeaderNav.defaultProps = {
  'data-.*': undefined,
}

HeaderNav.displayName = 'Header.Nav'
