import * as React from 'react'
import { Header as DsUiHeader, Icon } from '@ds/ui'
import { Reveal } from '../../Reveal'
import { Carousel } from '../../Carousel'
import { HeaderBar } from '../HeaderBar'
import { HeaderPrivateProps, HeaderPhoneMenuState, HeaderItem } from '../types'
import { HeaderPhoneMainMenu } from './HeaderPhoneMainMenu'
import { HeaderPhoneAppSwitchMenu } from './HeaderPhoneAppSwitchMenu'
import { HeaderPhoneTabItemMenu } from './HeaderPhoneTabItemMenu'
import { HeaderPhoneProfileMenu } from './HeaderPhoneProfileMenu'
import { HeaderAppDescription } from '../Common/HeaderAppDescription'
import { HeaderPrimaryMenuButton } from '../Common/HeaderPrimaryMenuButton'
import { initialFocusSelector } from '../utils'
import styles from '../styles'
import { FooterLanguageSelectorPhoneMenu } from '../../Footer/FooterLanguageSelectorPhoneMenu'
import { sortedLocaleAndDisplayName } from '../../Footer/localeDisplay'
import { createFooterItem } from './createFooterItem'
import { isEmpty } from 'lodash'

const menuWidth = 260

const initialMenuState: HeaderPhoneMenuState = { type: 'main' }

export const HeaderPhone: React.FunctionComponent<HeaderPrivateProps> = (
  props
) => {
  const menuButtonRef = React.useRef<HTMLButtonElement>(null)
  const [menuShowing, setMenuShowing] = React.useState(false)
  const [navHistory, setNavHistory] = React.useState<HeaderPhoneMenuState[]>([])
  const [currentMenuState, setCurrentMenuState] =
    React.useState<HeaderPhoneMenuState>(initialMenuState)
  const [previousMenuState, setPreviousMenuState] =
    React.useState<HeaderPhoneMenuState>()
  const [currentNavDirection, setCurrentNavDirection] = React.useState<
    'right' | 'left'
  >('left')
  const [showLangSelector, setShowLangSelector] = React.useState<boolean>(false)

  return (
    <>
      <HeaderBar
        kind="phone"
        visible={true}
        left={
          <>
            {renderMenuButton()}
            {renderAppDescription()}
          </>
        }
        right={<>{renderSearchHeaderButton()}</>}
      />
      {renderMenuContainer()}
      {showLangSelector && renderLanguageSelector()}
    </>
  )

  function renderMenuButton() {
    return (
      <HeaderPrimaryMenuButton
        onLoggingEvent={props.onLoggingEvent}
        icon="menuHamburger"
        text={props.translate('HDR:show-site-nav')}
        forwardedRef={menuButtonRef}
        onClick={() => setMenuShowing(true)}
        data-qa="header-mobile-app-menu-button"
        expanded={menuShowing}
      />
    )
  }

  function renderAppDescription() {
    return (
      <HeaderAppDescription
        translate={props.translate}
        homeUrl={props.homeUrl}
        appName={props.appName}
        appId={props.appId}
        appLogoUrl={props.appLogoUrl}
        isPhone={true}
        onHomeClick={props.onHomeClick}
        onLoggingEvent={props.onLoggingEvent}
      />
    )
  }

  function renderMenuContainer() {
    return (
      <Reveal
        distance={menuWidth}
        from="left"
        visible={menuShowing}
        onCancel={() => {
          setMenuShowing(false)
          resetFocus()
        }}
        onHideComplete={() => resetState()}
        focusSelector={initialFocusSelector}
        trapTabNavigation={false}
      >
        {renderMenuContents()}
      </Reveal>
    )
  }

  function renderMenuContents() {
    const oldPane = previousMenuState ? renderMenu(previousMenuState) : null
    const newPane = renderMenu(currentMenuState)
    return (
      <Carousel
        oldPane={oldPane}
        newPane={newPane}
        slideDirection={currentNavDirection}
        paneWidth={menuWidth}
        trapTabNavigation={true}
        focusSelector={initialFocusSelector}
      />
    )
  }

  function renderMenu(menuState: HeaderPhoneMenuState) {
    switch (menuState.type) {
      case 'main':
        return renderMainMenu(menuState)
      case 'appswitch':
        return renderAppSwitchMenu(menuState)
      case 'profile':
        return renderProfileMenu(menuState)
      case 'tab':
        return renderTabItemMenu(menuState)
      default:
        return renderMainMenu(menuState)
    }
  }

  function renderMainMenu(_menuState: HeaderPhoneMenuState) {
    const customItems: HeaderItem[] = props.customMobileItems || []
    if (props.mobileFooter) {
      customItems.push(
        createFooterItem(props.mobileFooter, props.locale, props.translate)
      )
    }
    return (
      <HeaderPhoneMainMenu
        {...props}
        customMobileItems={isEmpty(customItems) ? undefined : customItems}
        showLangSelector={() => setShowLangSelector(true)}
        onNavigate={(fromState, toState) => handleNavigate(fromState, toState)}
        onCloseRequest={(action) => handleCloseRequest(action)}
        onBackRequest={handleBackRequest}
      />
    )
  }

  function renderAppSwitchMenu(_menuState: HeaderPhoneMenuState) {
    return (
      <HeaderPhoneAppSwitchMenu
        translate={props.translate}
        appId={props.appId}
        accountGuid={props.accountGuid}
        switchableApps={props.switchableApps}
        onBackRequest={handleBackRequest}
        onLoggingEvent={props.onLoggingEvent}
        onAppSwitch={props.onAppSwitch}
        onShowAppSwitch={props.onShowAppSwitch}
      />
    )
  }

  function renderTabItemMenu(menuState: HeaderPhoneMenuState) {
    return (
      <HeaderPhoneTabItemMenu
        translate={props.translate}
        activeTabId={props.activeTabId}
        menuState={menuState}
        onNavigate={(fromState, toState) => handleNavigate(fromState, toState)}
        onCloseRequest={(action) => handleCloseRequest(action)}
        onBackRequest={handleBackRequest}
      />
    )
  }

  function renderSearchHeaderButton() {
    return props.searchButtonAction ? (
      <DsUiHeader.ActionItem
        iconElement={
          <Icon kind="search" size={styles.headerStandardIconSize} />
        }
        text={props.translate('search')}
        hideText={true}
        onClick={(event) => {
          props.searchButtonAction &&
            props.searchButtonAction.onClick &&
            props.searchButtonAction.onClick!(event.currentTarget)
        }}
        href={props.searchButtonAction.href}
        rel={props.searchButtonAction.rel}
        target={props.searchButtonAction.target}
        marginLeft={false}
        data-qa="header-search-link"
        selected={props.activeTabId === 'SEARCH'}
      />
    ) : props.onSearchClick ? (
      <DsUiHeader.ActionItem
        iconElement={
          <Icon kind="search" size={styles.headerStandardIconSize} />
        }
        text={props.translate('search')}
        hideText={true}
        onClick={(event) => props.onSearchClick!(event.currentTarget)}
        marginLeft={true}
        data-qa="header-search-button"
        selected={props.activeTabId === 'SEARCH'}
      />
    ) : undefined
  }

  function renderProfileMenu(_menuState: HeaderPhoneMenuState) {
    return (
      <HeaderPhoneProfileMenu
        translate={props.translate}
        locale={props.locale}
        accountId={props.accountId}
        user={props.user}
        profileImage={props.profileImage}
        showManageProfile={props.showManageProfile}
        onOpenProfileSite={props.onOpenProfileSite}
        onAccountSwitch={props.onAccountSwitch}
        onLogoff={props.onLogoff}
        customProfileChoices={props.customProfileChoices}
        profileExtraInfo={props.profileExtraInfo}
        onCloseRequest={(action) => handleCloseRequest(action)}
        onBackRequest={handleBackRequest}
      />
    )
  }

  function renderLanguageSelector() {
    return (
      props.languageSelector && (
        <Reveal
          distance={200}
          from="bottom"
          visible={showLangSelector}
          onCancel={() => setShowLangSelector(false)}
          overflow="auto"
        >
          <FooterLanguageSelectorPhoneMenu
            localeDescriptions={sortedLocaleAndDisplayName(
              props.languageSelector.locales
            )}
            currentLocale={props.locale}
            onLocaleSelected={(locale) => {
              if (props.languageSelector) {
                props.languageSelector.onLocaleSelected(locale)
              }
              setShowLangSelector(false)
            }}
          />
        </Reveal>
      )
    )
  }

  function handleBackRequest() {
    const backMenu = navHistory.pop()
    if (backMenu) {
      setCurrentNavDirection('right')
      setNavHistory([...navHistory])
      setPreviousMenuState(currentMenuState)
      setCurrentMenuState(backMenu)
    }
  }

  function handleNavigate(
    from: HeaderPhoneMenuState,
    to: HeaderPhoneMenuState
  ) {
    setCurrentNavDirection('left')
    setCurrentMenuState(from)
    setNavHistory([...navHistory, from])
    setPreviousMenuState(from)
    setCurrentMenuState(to)
  }

  function handleCloseRequest(action?: () => void) {
    setMenuShowing(false)
    if (action) {
      action()
    }
  }

  function resetState() {
    setCurrentMenuState(initialMenuState)
    setCurrentNavDirection('right')
    setPreviousMenuState(undefined)
    setNavHistory([])
  }

  function resetFocus() {
    if (menuButtonRef.current) {
      menuButtonRef.current.focus()
    }
  }
}
