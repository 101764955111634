import * as React from 'react'
import { Header as DsUiHeader, OliveIcon, Icon } from '@ds/ui'
import { HeaderLogHandler } from '../types'

/*

    Renders the primary nav button (waffle or hamburger)

*/

const primaryButtonIconSize = 24

export interface HeaderPrimaryMenuButtonProps {
  text: string
  icon: OliveIcon
  forwardedRef: React.RefObject<HTMLElement>
  onClick: () => void
  'data-qa': string
  expanded: boolean
  onLoggingEvent: HeaderLogHandler
}

export const HeaderPrimaryMenuButton: React.FunctionComponent<HeaderPrimaryMenuButtonProps> =
  (props) => {
    return (
      <DsUiHeader.ActionItem
        iconElement={<Icon kind={props.icon} size={primaryButtonIconSize} />}
        text={props.text}
        hideText={true}
        forwardedRef={props.forwardedRef}
        onClick={() => props.onClick()}
        marginRight={true}
        data-qa={props['data-qa']}
        aria-expanded={props.expanded ? 'true' : 'false'}
      />
    )
  }
