import * as React from 'react'
import styles from './styles'
import { useWindowWidth } from '@ds/react-utils'
import { useHeaderContext } from '@ds/ui'

/*

    Header bar (full width container for all header buttons).
    Left and right content is left/right aligned.  The middle content will consume the remainder
    of space available (it will be centered in the space available, not centered in the component).

    If either onContentFits or onContentClipped props are set the component will track it's
    preferred content width relative to the window width.
    When the width is inadequate it will call onContentClipped.  If the width becomes adequate it
    will call onContentFits.

*/

export interface HeaderBarProps {
  left?: React.ReactNode
  middle?: React.ReactNode
  right?: React.ReactNode
  onContentFits?: () => void
  onContentClipped?: () => void
  children?: undefined
  visible?: boolean
  kind?: 'phone' | 'desktop'
}

export const HeaderBar: React.FunctionComponent<HeaderBarProps> = (props) => {
  const measuringBarRef = React.createRef<HTMLDivElement>()
  const measuringBarLeftRef = React.createRef<HTMLDivElement>()
  const measuringBarMiddleRef = React.createRef<HTMLDivElement>()
  const measuringBarRightRef = React.createRef<HTMLDivElement>()

  const renderingBarRef = React.createRef<HTMLDivElement>()
  const renderingBarLeftRef = React.createRef<HTMLDivElement>()
  const renderingBarMiddleRef = React.createRef<HTMLDivElement>()
  const renderingBarRightRef = React.createRef<HTMLDivElement>()

  const preferredWidthRef = React.useRef(0)
  const availableWidthRef = React.useRef(0)
  const contentFitsRef = React.useRef<boolean | undefined>(undefined)

  if (shouldTrackWidth()) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    availableWidthRef.current = useWindowWidth(300) // forces refresh on window resize

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useLayoutEffect(() => {
      measurePreferredWidth()
    })

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      const fitPreviously = contentFitsRef.current
      const fitsNow = preferredWidthRef.current <= availableWidthRef.current
      contentFitsRef.current = fitsNow

      if (fitPreviously !== fitsNow) {
        notifyFitness(fitsNow)
      }
    })
  }

  const headerContext = useHeaderContext()

  return (
    <>
      {shouldTrackWidth() ? (
        <header css={styles.headerBarMeasuringCSS} ref={measuringBarRef}>
          <div css={styles.headerBarLeftCSS} ref={measuringBarLeftRef}>
            {}
          </div>
          <div
            css={styles.headerBarMiddleMeasuringCSS}
            ref={measuringBarMiddleRef}
          >
            {}
          </div>
          <div css={styles.headerBarRightCSS} ref={measuringBarRightRef}>
            {}
          </div>
        </header>
      ) : null}
      <header
        css={{
          ...styles.headerBarCSS({ dark: headerContext?.dark }),
          display: props.visible ? 'flex' : 'none',
        }}
        ref={renderingBarRef}
        data-header-kind={props.kind}
      >
        <div css={styles.headerBarLeftCSS} ref={renderingBarLeftRef}>
          {props.left}
        </div>
        <div css={styles.headerBarMiddleCSS} ref={renderingBarMiddleRef}>
          {props.middle}
        </div>
        <div css={styles.headerBarRightCSS} ref={renderingBarRightRef}>
          {props.right}
        </div>
      </header>
    </>
  )

  function shouldTrackWidth() {
    return props.onContentFits || props.onContentClipped
  }

  function measurePreferredWidth() {
    /*
            1.) Clone the contents of the rendered bar into a copy of the hidden ("measuring") bar
            2.) Measure the width used.  This is preferred width.

            The layout CSS of the measuring bar is slightly different. The 3 sections
            are layed out sequentially instead of the left-justify/remainder/right-justify
            of the rendered bar. Laying out sequentially allows the calculation of total
            space needed.
        */

    if (measuringBarRef.current) {
      const clonedMeasuringBarDiv = measuringBarRef.current.cloneNode(
        true
      ) as HTMLDivElement
      clonedMeasuringBarDiv.innerHTML = ''
      const leftDiv = cloneRenderingtoMeasuring(
        renderingBarLeftRef,
        measuringBarLeftRef
      )
      const middleDiv = cloneRenderingtoMeasuring(
        renderingBarMiddleRef,
        measuringBarMiddleRef
      )
      const rightDiv = cloneRenderingtoMeasuring(
        renderingBarRightRef,
        measuringBarRightRef
      )
      clonedMeasuringBarDiv.style.width = '5000px'
      if (leftDiv && middleDiv && rightDiv) {
        clonedMeasuringBarDiv.appendChild(leftDiv)
        clonedMeasuringBarDiv.appendChild(middleDiv)
        clonedMeasuringBarDiv.appendChild(rightDiv)
        document.body.appendChild(clonedMeasuringBarDiv)
        preferredWidthRef.current = Math.ceil(
          xMax(rightDiv) + styles.headerBarHorizontalPadding
        )
        document.body.removeChild(clonedMeasuringBarDiv)
      }
    }
  }

  function cloneRenderingtoMeasuring(
    from: React.RefObject<HTMLDivElement>,
    to: React.RefObject<HTMLDivElement>
  ) {
    const fromDiv = from.current
    const toDiv = to.current
    if (fromDiv && toDiv) {
      const clonedFromDiv = fromDiv.cloneNode(true) as HTMLDivElement
      const clonedToDiv = toDiv.cloneNode(true) as HTMLDivElement
      clonedToDiv.innerHTML = ''
      Array.from(clonedFromDiv.children).forEach((element) => {
        clonedToDiv.appendChild(element)
      })
      return clonedToDiv
    }
    return undefined
  }

  function notifyFitness(fits: boolean) {
    if (fits && props.onContentFits) {
      props.onContentFits()
    }
    if (!fits && props.onContentClipped) {
      props.onContentClipped()
    }
  }
}

function xMax(element: HTMLElement) {
  const computedStyle = getComputedStyle(element)
  const marginRight = parseFloat(computedStyle.marginRight || '')
  return (
    element.offsetLeft +
    element.getBoundingClientRect().width +
    (isNaN(marginRight) ? 0 : marginRight)
  )
}
