import { keyboardEventKeys } from '../variables'
import { keyboardNavigableElements } from './keyboardNavigableElements'

/**
 *  Traps tab navigation within the container, setting focus on the first of:
 *  1. the provided initialFocus element
 *  2. the first navigable element in the container
 *  3. the container
 *
 *  @param {HTMLElement} container
 *  @param {HTMLElement} initialFocus - A descendant of the container to receive focus.
 *  @param {boolean} preventScroll - Prevent scrolling the focused element into view
 */
export const trapTabNavigation = (
  container: HTMLElement | SVGElement,
  initialFocus?: HTMLElement,
  preventScroll = false
) => {
  const keyboardNavigable = keyboardNavigableElements(container)

  const firstNavigableElement = keyboardNavigable[0]
  const lastNavigableElement = keyboardNavigable[keyboardNavigable.length - 1]

  const allContainerNodes = Array.from(container.querySelectorAll('*'))

  const firstNavigableElementIndex = allContainerNodes.indexOf(
    firstNavigableElement
  )
  const lastNavigableElementIndex =
    allContainerNodes.indexOf(lastNavigableElement)

  const initialFocusIndex = initialFocus
    ? allContainerNodes.indexOf(initialFocus)
    : -1

  const isInitialFocusBeforeFirstNavigable =
    initialFocusIndex !== -1 && initialFocusIndex < firstNavigableElementIndex

  const isInitialFocusAfterLastNavigable =
    lastNavigableElementIndex !== -1 &&
    lastNavigableElementIndex < initialFocusIndex

  const isInitialFocusWithoutKeyboardNavigable =
    initialFocusIndex !== -1 && !keyboardNavigable.length

  const neitherInitialFocusNorKeyboardNavigable =
    initialFocusIndex === -1 && !keyboardNavigable.length

  if (keyboardNavigable.length) {
    firstNavigableElement.addEventListener(
      'keydown',
      (event) => {
        if (event.key === keyboardEventKeys.Tab && event.shiftKey) {
          lastNavigableElement.focus({ preventScroll })
          event.preventDefault()
        }
      },
      false
    )

    lastNavigableElement.addEventListener(
      'keydown',
      (event) => {
        if (event.key === keyboardEventKeys.Tab && !event.shiftKey) {
          firstNavigableElement.focus({ preventScroll })
          event.preventDefault()
        }
      },
      false
    )
  }

  if (isInitialFocusBeforeFirstNavigable) {
    initialFocus?.addEventListener(
      'keydown',
      (event: KeyboardEvent) => {
        if (event.key === keyboardEventKeys.Tab && event.shiftKey) {
          lastNavigableElement.focus({ preventScroll })
          event.preventDefault()
        }
      },
      false
    )
  }

  if (isInitialFocusAfterLastNavigable) {
    initialFocus?.addEventListener(
      'keydown',
      (event: KeyboardEvent) => {
        if (event.key === keyboardEventKeys.Tab && !event.shiftKey) {
          firstNavigableElement.focus({ preventScroll })
          event.preventDefault()
        }
      },
      false
    )
  }

  if (isInitialFocusWithoutKeyboardNavigable) {
    initialFocus?.addEventListener(
      'keydown',
      (event: KeyboardEvent) => {
        if (event.key === keyboardEventKeys.Tab) {
          event.preventDefault()
        }
      },
      false
    )
  }

  if (neitherInitialFocusNorKeyboardNavigable) {
    container.addEventListener(
      'keydown',
      (event: Event) => {
        const evt = event as KeyboardEvent
        if (evt.key === keyboardEventKeys.Tab) {
          evt.preventDefault()
        }
      },
      false
    )
  }

  if (initialFocus) {
    initialFocus.focus({ preventScroll })
  } else if (keyboardNavigable.length) {
    firstNavigableElement.focus({ preventScroll })
  } else {
    container.focus({ preventScroll })
  }
}
