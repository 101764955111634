import { breakpoints } from '../../../../utilities'
import type { Tokens } from '../../../types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    ToolbarDropdownInput: {
      input: {
        ...tokens.typography.bodyMedium,
      },

      '.input-text-box-wrapper': {
        backgroundColor: tokens.form.defaultBackgroundColor,

        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          height: tokens.toolbar.mobileHeight,
        },
      },

      button: {
        backgroundColor: tokens.toolbar.defaultBackgroundColor,
        paddingLeft: '9px',

        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          minWidth: '40px',
        },
      },

      '&:hover, &[aria-expanded="true"]': {
        backgroundColor: 'transparent',

        '.input-text-box-wrapper': {
          backgroundColor: tokens.toolbar.hoverBackgroundColor,
        },

        button: {
          borderLeft: '1px solid transparent',
          backgroundColor: tokens.toolbar.hoverBackgroundColor,

          '&:active': {
            backgroundColor: tokens.toolbar.activeBackgroundColor,
          },
        },
      },
    },
  } as const
}
