import { Tokens } from '../../../theming/types'

import { styles } from '../../../utilities'
import colors from '../../../theming/docusign-themes/olive/colors'
import { CreateFocusCss } from '../../../styles'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: 0,
        color: colors.white,
        cursor: 'pointer',
        display: 'flex',
        fontFamily: tokens.font.heading,
        fontSize: '13px',
        fontWeight: 700,
        height: '52px',
        letterSpacing: '0.66px',
        padding: '0 12px',
        position: 'relative',
        textDecoration: 'none',
        textTransform: 'uppercase',
        transition: `
          background-color 0.2s ease,
          color 0.2s ease
        `,
        whiteSpace: 'nowrap',

        '&::after': {
          backgroundColor: 'transparent',
          bottom: '14px',
          border: 'none',
          content: '""',
          display: 'block',
          height: '2px',
          left: 0,
          margin: 'auto',
          position: 'absolute',
          right: 0,
          transition: 'background-color 0.2s ease',
          width: 'calc(100% - 12px * 2)',
        },

        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: colors.DocuSignBlueLight,
          color: colors.white,
          textDecoration: 'none',
        },

        '&:active': {
          outline: 'none',
        },

        ...CreateFocusCss({
          outlineColor: colors.white,
          outlineStyle: 'dotted',
          outlineWidth: '1px',
          outlineOffset: '-4px',
        }),
      },

      icon: {
        display: 'flex',

        'svg, img': {
          fill: 'currentColor',
          width: tokens.icon.defaultSize,
        },
      },

      badge: {
        display: 'flex',
        position: 'absolute',
        right: 0,
        top: '12px',
      },
    },

    selected: {
      wrap: {
        color: colors.white,

        '&::after': {
          backgroundColor: colors.white,
        },

        '&:hover, &:active, &[aria-expanded="true"]': {
          color: colors.white,

          '&::after': {
            backgroundColor: colors.white,
          },
        },
      },
    },

    isIconOnly: {
      wrap: {
        padding: '0 12px',
      },

      text: {
        ...styles.visuallyHidden,
      },

      icon: {
        margin: 0,
      },

      badge: {
        right: '6px',
      },
    },

    hasIconBeforeText: {
      icon: {
        marginRight: '6px',
      },
    },

    hasIconAfterText: {
      icon: {
        marginLeft: '6px',
      },
    },

    hasMarginLeft: {
      wrap: {
        marginLeft: '12px',
      },
    },

    hasMarginRight: {
      wrap: {
        marginRight: '12px',
      },
    },

    hasMenuTrigger: {
      wrap: {
        paddingRight: '27px',

        '&::after': {
          borderColor: `${colors.white} transparent transparent transparent`,
          borderStyle: 'solid',
          borderWidth: '6px 4px 0 4px',
          content: '""',
          display: 'block',
          height: 0,
          pointerEvents: 'none',
          position: 'absolute',
          right: '12px',
          top: 'calc(50% - 3px)',
          width: 0,
        },
      },
    },

    underlineOnHover: {
      wrap: {
        color: colors.DocuSignBluePale,

        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: 'transparent',
          color: colors.DocuSignBluePale,

          '&::after': {
            backgroundColor: colors.DocuSignBluePale,
          },
        },
      },
    },
  } as const
}
