import React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'
import { AnchorForwardRef, EventListenerProps } from '../../../types'
import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'
import { DocuSignLogo } from '../../DocuSignLogo'
import baseStyles from './styles'
import { useHeaderContext } from '../HeaderContext'

export interface HeaderLogoProps extends EventListenerProps<HTMLAnchorElement> {
  /**
   * Adds an aria-label text to the custom logo image.
   */
  alt?: string
  /**
   * A React ref to assign to the HTML label element.
   */
  forwardedRef?: AnchorForwardRef
  /**
   * The text to be displayed next to the logo image (usually the name of the application).
   */
  text?: string
  /**
   * URL for navigation upon clicking the logo image.
   */
  href?: string
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

export function HeaderLogo(props: HeaderLogoProps) {
  const { alt, forwardedRef, href = '/', text, ...restProps } = props

  const headerContext = useHeaderContext()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'HeaderLogo', {
    ...props,
    ...headerContext,
  })

  /**
   * Custom account branding sets the custom logo as a background image.
   * So we can check the `styles.link` object if it has the backgroundImage property
   *  to determine if custom branding is applied.
   */
  const hasCustomLogo = styles.link.backgroundImage

  const DocuSignLogoNode = !hasCustomLogo && (
    <div css={styles.image}>
      <DocuSignLogo />
    </div>
  )

  const TextNode = text && !hasCustomLogo && (
    <span css={styles.text}>{text}</span>
  )

  return (
    <div css={styles.wrap}>
      <a
        {...dataProps(restProps)}
        {...onProps(restProps)}
        aria-label={hasCustomLogo && alt}
        css={styles.link}
        href={href}
        onMouseEnter={restProps.onMouseEnter}
        onMouseLeave={restProps.onMouseLeave}
        ref={forwardedRef}
      >
        {DocuSignLogoNode}
        {TextNode}
      </a>
    </div>
  )
}

HeaderLogo.propTypes = {
  alt: PropTypes.string,
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  href: PropTypes.string,
  'on[A-Z].*': PropTypes.func,
  text: PropTypes.string,
}

HeaderLogo.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  alt: undefined,
  forwardedRef: undefined,
  href: '/',
  text: undefined,
}

HeaderLogo.displayName = 'Header.Logo'
