import { CSSObject } from '@emotion/core'
import { DocusignAppId } from './types'

const headerBarHorizontalPadding = 16

const headerHeight = 64
const phoneMenuRowHeight = 72

const backgroundColor = 'white'
const textColor = '#0f1114'
const barBottomBorderColor = '#d0d0d0'
const barVerticalSeparatorColor = 'rgba(17,16,25,0.15)'
const focusOutlineColor = 'rgba(17,16,25,0.6)' // Ink black_alpha60

const marketingButtonBlue = '#0069ec'

const menuTitleFontSize = 18

const headerStandardIconSize = 20

const DS_INDIGO_FONT_STACK =
  '"DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif'

const headerFocusCSS: CSSObject = {
  '&:focus': {
    borderRadius: 0,
    boxShadow: 'none',
    outline: `2px solid ${focusOutlineColor}`,
    outlineOffset: '-4px',
  },
}

const headerCSS: CSSObject = {
  label: 'HEADER',
  position: 'relative',
  height: headerHeight,
  fontFamily: DS_INDIGO_FONT_STACK,
}

const headerBarCSS = ({ dark }: { dark?: boolean }): CSSObject => {
  return {
    label: 'HEADER_BAR',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    height: headerHeight,
    backgroundColor: dark ? '#191823' : 'white',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    paddingLeft: headerBarHorizontalPadding,
    paddingRight: headerBarHorizontalPadding,
  }
}

const headerBarMeasuringCSS: CSSObject = {
  ...headerBarCSS,
  label: 'HEADER_BAR_HIDDEN_MEASURING_CONTAINER',
  justifyContent: 'flex-start',
  display: 'flex',
  width: 0,
  visibility: 'hidden',
  backgroundColor: 'yellow',
}

const headerBarLeftCSS: CSSObject = {
  label: 'HEADER_BAR_LEFT',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
}

const headerBarMiddleCSS: CSSObject = {
  label: 'HEADER_BAR_MIDDLE',
  display: 'flex',
  justifyContent: 'center',
  margin: '0px 8px',
  alignItems: 'center',
}

const headerBarMiddleMeasuringCSS: CSSObject = {
  ...headerBarMiddleCSS,
  label: 'HEADER_BAR_MIDDLE_MEASURING',
  justifyContent: 'start',
}

const headerBarRightCSS: CSSObject = {
  label: 'HEADER_BAR_RIGHT',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
}

const headerBarTabsCSS: CSSObject = {
  label: 'HEADER_BAR_TABS',
  display: 'flex',
  listStyleType: 'none',
  margin: '0px',
  padding: '0px',
}

const headerLocalNavSeparatorCSS: CSSObject = {
  label: 'HEADER_LOCAL_NAV_SEPARATOR',
  marginLeft: 40,
}

const appDescriptionCSS = ({ dark }: { dark?: boolean }): CSSObject => {
  return {
    label: 'HEADER_APP_DESCRIPTION',
    width: 0,
    minWidth: 0,
    '&:focus': {
      boxShadow: 'none',
      outlineColor: dark ? 'white' : focusOutlineColor,
      outlineStyle: 'solid',
      outlineWidth: '2px',
      outlineOffset: '12px',
    },
  }
}

const appSwitchLogoWrapper: CSSObject = {
  height: '24px',
  maxWidth: '114px',
  paddingTop: 2,
}

const appDescriptionCSSForApp = ({
  appId,
  dark,
}: {
  appId: DocusignAppId
  dark?: boolean
}) => {
  let width = 0
  const height = 24
  switch (appId) {
    case 'Admin':
      width = 186
      break
    case 'CLM':
      width = 166
      break
    case 'DTR':
      width = 194
      break
    case 'ESign':
      width = 236
      break
    case 'Insight':
      width = 191
      break
    default:
  }
  const minWidth = width
  const maxHeight = height

  const css: CSSObject = {
    ...appDescriptionCSS({ dark }),
    width,
    minWidth,
    height,
    marginTop: 4,
    '& img': {
      minWidth,
      maxHeight,
      ...(dark && {
        filter: 'brightness(0) invert(100%)',
        mixBlendMode: 'normal',
      }),
    },
  }
  return css
}

const appDescriptionNonProductCSS: CSSObject = {
  a: {
    margin: 0,
    paddingLeft: 0,
    paddingTop: 4,
  },
}

const appSwitcherMenuContainerCSS: CSSObject = {
  label: 'HEADER_APP_SWITCHER',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor,
  border: '1px solid rgba(17,16,25,0.15)',
  boxShadow: '0 8px 12px 0 rgba(17,16,25,0.15)',
  overflow: 'hidden',
  zIndex: 200,
}

const appSwitcherListCSS: CSSObject = {
  label: 'HEADER_APP_SWITCHER_LIST',
  listStyle: 'none',
  padding: '8px 0px',
  marginBlockStart: 0,
}

const verticalAlignCSS: CSSObject = {
  display: 'flex',
  alignItems: 'center',
}

const menuHeadingCSS: CSSObject = {
  label: 'HEADER_MENU_HEADING',
  position: 'relative',
  padding: 24,
  height: phoneMenuRowHeight,
}

const menuHeadingCenteredCSS: CSSObject = {
  label: 'HEADER_MENU_HEADING_CENTERED',
  alignItems: 'center',
  borderBottom: '1px solid #d0d0d0',
  padding: '0 16px',
  display: 'flex',
}

const appSwitchHeadingCSS: CSSObject = {
  ...menuHeadingCenteredCSS,
  label: 'APP_SWITCH_MENU_HEADING',
  borderBottom: undefined,
  height: headerHeight - 2,
}

const menuCloseButtonCSS: CSSObject = {
  ...headerFocusCSS,
  label: 'HEADER_MENU_CLOSE',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  flex: '0 0 auto',
  height: headerHeight - 1,
  marginRight: '12px',
  padding: '3px 12px 0',
}

const menuCloseFullHeaderButtonCSS: CSSObject = {
  marginLeft: '-1px',
}

const menuProfileChoiceList: CSSObject = {
  label: 'HEADER_PROFILE_CHOICE_LIST',
  marginTop: 10,
  listStyle: 'none',
  paddingLeft: 0,
}

const phoneMenuTitleCSS: CSSObject = {
  label: 'HEADER_PHONE_MENU_TITLE',
  fontSize: menuTitleFontSize,
  fontWeight: 500,
  margin: '0 0 0 8px',
}

const menuSeparatorCSS: CSSObject = {
  label: 'HEADER_MENU_SEPARATOR',
  border: 0,
  height: 1,
  background: barBottomBorderColor,
  margin: 0,
}

const phoneMenuScrollableRowsCSS: CSSObject = {
  label: 'HEADER_PHONE_SCROLL_ROWS',
  flex: '1 1 auto',
  listStyle: 'none',
  margin: 0,
  overflowY: 'auto',
  padding: 0,
}

const phoneMainMenuScrollableRowsCSS: CSSObject = {
  label: 'HEADER_PHONE_MAIN_SCROLLABLE_ROW',
  flex: '1 1 auto',
  listStyle: 'none',
  margin: 0,
  overflowY: 'auto',
  padding: 0,
}

const phoneMainMenuItemListCSS: CSSObject = {
  label: 'HEADER_PHONE_MAIN_LIST',
  borderTop: '1px solid #d0d0d0',
  margin: 0,
  listStyle: 'none',
  paddingLeft: 0,
}

const customLogoCSS: CSSObject = {
  label: 'HEADER_DESKTOP_CUSTOM_LOGO',
  height: 32,
  width: 'auto',
  marginRight: 16,
}

const phoneCustomLogoCSS: CSSObject = {
  label: 'HEADER_PHONE_CUSTOM_LOGO',
  height: 24,
  width: 'auto',
}

const phoneProfileRowTextCSS: CSSObject = {
  label: 'HEADER_PHONE_PROFILE_ROW_TEXT',
  maxWidth: 160,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const phoneCustomContentCSS: CSSObject = {
  label: 'HEADER_PHONE_CUSTOM_CONTENT',
  overflow: 'auto',
}

const menuProfileUserCSS: CSSObject = {
  label: 'HEADER_PROFILE_USER',
  color: textColor,
  fontFamily: 'inherit',
  fontWeight: 200,
  padding: '16px 24px 24px 24px',
}

const phoneProfileUserCSS: CSSObject = {
  ...menuProfileUserCSS,
  label: 'HEADER_PHONE_PROFILE_USER',
  padding: '16px 12px 24px 24px',
}

const phoneProfileUserNameCSS: CSSObject = {
  label: 'HEADER_PROFILE_USER_NAME',
  fontSize: 16,
  fontWeight: 'bold',
  maxWidth: 200,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const phoneMainMenuProfileUserNameCSS: CSSObject = {
  label: 'HEADER_MAIN_MENU_PROFILE_USER_NAME',
  maxWidth: 160,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const phoneProfileUserDetailCSS: CSSObject = {
  label: 'HEADER_PROFILE_USER_DETAIL',
  fontSize: 14,
  marginTop: 6,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const phoneProfileButtonCSS: CSSObject = {
  label: 'HEADER_PROFILE_MANAGE_BUTTON',
  marginTop: 16,
}

const tabsSeparatorCSS = ({ dark }: { dark?: boolean }): CSSObject => {
  return {
    label: 'HEADER_TABS_SEPARATOR',
    borderLeftColor: dark
      ? 'rgba(255, 255, 255, 0.45)'
      : barVerticalSeparatorColor,
    borderLeftStyle: 'solid',
    borderLeftWidth: '1px',
    margin: '14px 0px',
  }
}

const customLogoSeparatorCSS: CSSObject = {
  label: 'HEADER_CUSTOM_LOGO_SEPARATOR',
  borderLeft: `1px solid ${barVerticalSeparatorColor}`,
  height: headerHeight,
  marginRight: 16,
}

const mainButtonCSS: CSSObject = {
  label: 'HEADER_MAIN_BUTTON',
  marginRight: 20,
}

const marketingButtonTextCSS: CSSObject = {
  label: 'HEADER_MARKETING_BUTTON_SECTION',
  color: marketingButtonBlue,
  fontSize: '16px',
  marginRight: '16px',
  fontWeight: 500,
}

const marketingButtonSectionCSS: CSSObject = {
  label: 'HEADER_MARKETING_BUTTON_SECTION',
  marginRight: 0,
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
}

export default {
  verticalAlignCSS,
  headerCSS,
  headerBarCSS,
  headerBarHorizontalPadding,
  headerBarMeasuringCSS,
  headerBarLeftCSS,
  headerBarMiddleCSS,
  headerBarMiddleMeasuringCSS,
  headerBarRightCSS,
  headerBarTabsCSS,
  headerLocalNavSeparatorCSS,
  appDescriptionCSS,
  appDescriptionCSSForApp,
  appDescriptionNonProductCSS,
  appSwitcherMenuContainerCSS,
  appSwitcherListCSS,
  appSwitchLogoWrapper,
  appSwitchHeadingCSS,
  menuHeadingCenteredCSS,
  menuHeadingCSS,
  menuProfileChoiceList,
  menuCloseButtonCSS,
  menuCloseFullHeaderButtonCSS,
  menuSeparatorCSS,
  phoneMenuTitleCSS,
  phoneMenuScrollableRowsCSS,
  phoneMainMenuScrollableRowsCSS,
  phoneMainMenuItemListCSS,
  phoneMainMenuProfileUserNameCSS,
  customLogoCSS,
  phoneCustomLogoCSS,
  phoneProfileRowTextCSS,
  phoneCustomContentCSS,
  menuProfileUserCSS,
  phoneProfileUserCSS,
  phoneProfileUserNameCSS,
  phoneProfileUserDetailCSS,
  phoneProfileButtonCSS,
  customLogoSeparatorCSS,
  tabsSeparatorCSS,
  mainButtonCSS,
  marketingButtonTextCSS,
  marketingButtonSectionCSS,
  headerStandardIconSize,
}
