import * as React from 'react'
import { useTranslate } from '@ds/comp-private'
import { HeaderContext, Theme, InkDocuSignTheme } from '@ds/ui'
import {
  HeaderProps,
  DocusignApp,
  HeaderUserProfile,
  ImageURL,
  HeaderTranslateFunction,
  HeaderLogEvent,
} from './types'
import { HeaderPhone } from './Phone/HeaderPhone'
import { HeaderStandard } from './Desktop/HeaderStandard'
import { validateProps } from './propValidator'
import { HeaderAPIClient, cachedAPIClient } from './HeaderAPIClient'

import { useHtmlLang, useMountStatus } from '@ds/react-utils'
import styles from './styles'
import { logComponentError, logComponentHttpError } from '@ds/logging'

/*

    Global header for DocuSign apps.

    Uses the "me" api to fetch data given a me token.

    This component uses a "Stale while revalidate" approach to API responses.  As multi-page apps
    navigate from route to route (causing a page load and requests to the API) the Header width will
    resize to show the app switch waffle icon if the user is provisioned for more than one app. Likewise,
    the user's profile avatar changes. This causes the header parts to move as the header redoes the
    layout when the API responses arrive.

    It will cache REST API calls in session storage if the consumer provides a cachingOptions prop with useStorage:true

*/

const NO_ME_TOKEN_PROP_VALUE = 'NONE'

const STALE_CACHE_APPS = 'global-header-me/apps'
const STALE_CACHE_IMAGE = 'global-header-me/profileimage'
const STALE_CACHE_IDS = [STALE_CACHE_APPS, STALE_CACHE_IMAGE]
const STALE_CACHE_TIMESTAMP_PROP = 'cacheTimestamp'
const STALE_CACHE_EXPIRE_MINUTES = 60

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
  validateProps(props)

  const pageLocale = useHtmlLang()
  const locale = props.locale || pageLocale || 'en'
  const translate: HeaderTranslateFunction = useTranslate(locale)

  const onLoggingEvent = props.onLoggingEvent || defaultOnLoggingEvent

  const apiClientRef = React.useRef<HeaderAPIClient>()

  const [userProfile, setUserProfile] = React.useState<HeaderUserProfile>()
  const [availableApps, setAvailableApps] = React.useState<DocusignApp[]>([])
  const [profileImage, setProfileImage] = React.useState<ImageURL>()

  const [renderForPhone, setRenderForPhone] = React.useState(false)

  apiClientRef.current = props.apiClient
    ? props.apiClient
    : cachedAPIClient(
        props.meToken,
        props.apiRootUrl,
        onLoggingEvent,
        props.cachingOptions
      )

  const mountStatus = useMountStatus()

  React.useEffect(() => {
    if (mountStatus.mounted && !!props.forceApiRefresh) {
      apiClientRef.current?.clearCache()
    }
  }, [mountStatus.mounted, props.forceApiRefresh])

  React.useEffect(() => {
    async function fetchUserProfile() {
      try {
        const profile =
          props.meToken === NO_ME_TOKEN_PROP_VALUE
            ? undefined
            : await apiClientRef.current!.fetchUserProfile()
        if (mountStatus.mounted) {
          setUserProfile(profile)
        }
      } catch (error) {
        if (mountStatus.mounted) {
          setUserProfile(undefined)
        }
      }
    }

    if (props.disableProfile) {
      setUserProfile(undefined)
    } else if (props.userData) {
      setUserProfile(props.userData)
    } else {
      fetchUserProfile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.apiRootUrl,
    props.disableProfile,
    props.forceApiRefresh,
    props.meToken,
    props.userData,
  ])

  React.useEffect(() => {
    async function fetchAvailableApps() {
      try {
        const apps =
          props.meToken === NO_ME_TOKEN_PROP_VALUE
            ? []
            : await apiClientRef.current!.fetchApps()
        setCachedAPIResponse(STALE_CACHE_APPS, apps)
        if (mountStatus.mounted) {
          setAvailableApps(apps)
          if (props.onAppsFetched) {
            props.onAppsFetched(apps)
          }
        }
      } catch (error) {
        if (mountStatus.mounted) {
          setAvailableApps([])
        }
      }
    }
    if (props.disableAppSwitching) {
      setAvailableApps([])
    } else if (props.appList) {
      setAvailableApps(props.appList)
    } else {
      const cachedApps = getCachedAPIResponse(STALE_CACHE_APPS)
      if (cachedApps) {
        setAvailableApps(cachedApps)
      }
      fetchAvailableApps()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.apiRootUrl,
    props.disableAppSwitching,
    props.forceApiRefresh,
    props.meToken,
    props.appList,
  ])

  React.useEffect(() => {
    async function fetchProfileImage() {
      try {
        const image =
          props.meToken === NO_ME_TOKEN_PROP_VALUE
            ? undefined
            : await apiClientRef.current!.fetchProfileImage()
        setCachedAPIResponse(STALE_CACHE_IMAGE, image)
        if (mountStatus.mounted) {
          setProfileImage(image)
        }
      } catch (error) {
        if (mountStatus.mounted) {
          setProfileImage(undefined)
        }
      }
    }
    if (props.userProfileImage) {
      setProfileImage(props.userProfileImage)
    } else if (props.disableProfile || props.userData) {
      setProfileImage(undefined)
    } else {
      const cachedImage = getCachedAPIResponse(STALE_CACHE_IMAGE)
      if (cachedImage) {
        setProfileImage(cachedImage)
      }
      fetchProfileImage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.apiRootUrl,
    props.disableProfile,
    props.forceApiRefresh,
    props.meToken,
    props.userData,
    props.userProfileImage,
  ])

  const onLogoff = () => {
    clearFlashCache()
    apiClientRef.current?.clearCache()
    props.onLogoff()
  }

  const contextValue = React.useMemo(
    () => ({
      dark: props.dark,
    }),
    [props.dark]
  )

  /*

        The header is responsive but it does not use media queries.  Rather, it always renders
        the standard header (which has callbacks notifying regarding whether it can fit in the
        window). If there is not enough room for the standard header this component will continue
        to render it (as invisible) but will also render the phone header.

    */

  return (
    <Theme docuSignTheme={InkDocuSignTheme}>
      <HeaderContext.Provider value={contextValue}>
        <div css={styles.headerCSS}>
          <HeaderStandard
            {...props}
            onLogoff={onLogoff}
            enabled={!renderForPhone}
            switchableApps={availableApps}
            locale={locale}
            translate={translate}
            user={userProfile}
            profileImage={profileImage}
            onContentFits={() => {
              setRenderForPhone(false)
              if (props.onDesktop) {
                props.onDesktop()
              }
            }}
            onContentClipped={() => {
              setRenderForPhone(true)
              if (props.onMobile) {
                props.onMobile()
              }
            }}
            onLoggingEvent={onLoggingEvent}
            onOpenProfileSite={() => apiClientRef.current?.clearProfileCache()}
            onShowAppSwitch={() => apiClientRef.current?.clearAppCache()}
          />
          {renderForPhone && (
            <HeaderPhone
              {...props}
              onLogoff={onLogoff}
              switchableApps={availableApps}
              locale={locale}
              translate={translate}
              user={userProfile}
              profileImage={profileImage}
              onLoggingEvent={onLoggingEvent}
              onOpenProfileSite={() =>
                apiClientRef.current?.clearProfileCache()
              }
              onShowAppSwitch={() => apiClientRef.current?.clearAppCache()}
            />
          )}
        </div>
      </HeaderContext.Provider>
    </Theme>
  )
}

const defaultOnLoggingEvent = (event: HeaderLogEvent) => {
  if (event.isError) {
    if (event.httpError) {
      logComponentHttpError(event.httpError, 'GlobalHeader')
    } else {
      logComponentError(
        { message: event.description, error: event.error },
        'GlobalHeader'
      )
    }
  }
}

function getCachedAPIResponse(cacheId: string) {
  try {
    const cachedResponse = window.sessionStorage.getItem(cacheId)
    if (cachedResponse) {
      const parsedCachedResponse = JSON.parse(cachedResponse)
      const responseTimestamp =
        parsedCachedResponse[STALE_CACHE_TIMESTAMP_PROP] || 0
      const expired =
        responseTimestamp + STALE_CACHE_EXPIRE_MINUTES * 60 * 1000 < now()
      if (expired) {
        setCachedAPIResponse(cacheId, undefined)
        return undefined
      }
      return parsedCachedResponse.response
    }
    return undefined
  } catch (error) {
    return undefined
  }
}

function setCachedAPIResponse(cacheId: string, response: unknown) {
  try {
    if (response) {
      const cachedResponse = JSON.stringify({
        response,
        [STALE_CACHE_TIMESTAMP_PROP]: now(),
      })
      window.sessionStorage.setItem(cacheId, cachedResponse)
    } else {
      window.sessionStorage.removeItem(cacheId)
    }
  } catch (error) {
    // noop
  }
}

function clearFlashCache() {
  STALE_CACHE_IDS.forEach((id) => setCachedAPIResponse(id, undefined))
}

function now() {
  return new Date().getTime()
}
