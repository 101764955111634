import React, { ReactEventHandler } from 'react'
import PropTypes from 'prop-types'
import { mergeRefs } from '@ds/react-utils'
import { BaseMenuItem } from '../../../internal/components/BaseMenuItem'
import { deprecatedPropMessage, requiredPropMessage } from '../../../logging'
import { CustomPropTypes } from '../../../support'
import type { ButtonForwardRef, EventListenerProps } from '../../../types'
import type { DotBadgeProps } from '../../DotBadge'
import type { BadgeProps } from '../../Badge'
import { MenuItemDisclosure } from '../MenuItemDisclosure'
import { MenuButton } from '../MenuButton'
import { MenuGroup } from '../MenuGroup'
import { MenuWithState } from '../MenuWithState'

export interface MenuItemWithSubmenuProps
  extends EventListenerProps<HTMLButtonElement> {
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*'?: React.EventHandler<React.SyntheticEvent>
  /**
   * The text to present to assistive devices in order to identify the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  accessibilityText?: string
  /**
   * activeMenuItem is provided by a parent Menu.Button that monitors
   * Menu.Items (and Submenus).  The value is the Menu.Item that was
   * onMouseEnter'ed last.
   *
   * When the tracked active menu item is within the DOM of this submenu
   * the menu will be open.
   *
   * When the user mouses onto a sibling Menu.Item/Submenu this will
   * be false and close.
   */
  activeMenuItem?: HTMLElement | null
  /**
   * The "badge" element to display above the top-right of the Menu.Item.
   *
   * The normal use case for this would be to signify that there are notifications to be read
   * or actions to be taken, and a DotBadge element is provided to this prop to indicate such.
   */
  badge?: React.ReactElement<DotBadgeProps | BadgeProps>
  /**
   * The 'children' prop accepts a ReactNode but should always be Menu.Items
   * or Menu.ItemWithSubmenus
   *
   * `<Menu.ItemWithSubmenu> <Menu.Item /> </Menu.ItemWithSubmenu>`
   */
  children: React.ReactNode

  /**
   * An optional description.
   */
  description?: string
  /**
   * Applies the 'disabled' attribute.  Doesn't allow submenu to open.
   */
  disabled?: boolean
  /**
   * A React ref to assign to the HTML node representing the MenuItem's trigger
   * element, a HTML Button.
   */
  forwardedRef?: ButtonForwardRef
  /**
   * The title to present to assistive devices in order to identify the MenuGroup.
   *
   * This will default to the value passed in to text or accessibilityText if not provided.
   */
  menuGroupAccessibilityTitle?: string
  /**
   * Max height of the menu in any accepted numeric or percentage CSS unit.
   */
  menuMaxHeight?: string
  /**
   * The function to call when a 'keydown' event is fired.
   *
   * For navigation between menu items in a menu this should be the 'menuItemOnKeyDown' function
   * that comes from a Menu.Button when the <Menu.ItemWithSubMenu> is in the first/root menu.
   *
   * For nested menus this is wired up automatically.
   */
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>
  /**
   * The secondary text of the Menu.Item.
   * @deprecated – use `description` instead.
   */
  secondaryText?: string
  /**
   * The provided component will display at the start of the MenuItem.
   */
  startElement?: React.ReactNode
  /**
   * The text of the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  text?: string
}

const callSerial =
  (...fns: (ReactEventHandler | undefined)[]) =>
  (args: React.SyntheticEvent) => {
    Array.from(fns).forEach((fn) => typeof fn === 'function' && fn(args))
  }

export function MenuItemWithSubmenu(props: MenuItemWithSubmenuProps) {
  const {
    accessibilityText,
    activeMenuItem,
    description,
    disabled,
    forwardedRef,
    menuGroupAccessibilityTitle,
    menuMaxHeight,
    onClick,
    onKeyDown,
    secondaryText,
    text,
    ...restProps // gets passed to BaseMenuItem
  } = props

  const { 'data-qa': dataQa } = restProps

  if (!(text || accessibilityText)) {
    requiredPropMessage({
      component: 'Menu.ItemWithSubmenu',
      prop1: 'text',
      prop2: 'accessibilityText',
    })
  }

  if (secondaryText) {
    deprecatedPropMessage({
      component: 'Menu.ItemWithSubmenu',
      prop: 'secondaryText',
      newProp: 'description',
    })
  }

  /**
   * activeMenuItem is provided by a parent Menu.Button that monitors
   * Menu.Items (and Submenu).  The value is the Menu.Item that was
   * onMouseEnter'ed last.  When this menu opens the user will move
   * their mouse into the menu so it's necessary to maintain that state
   * in the Menu.Button (menu controller/manager)
   *
   * When the tracked active menu item is within the DOM of the parent
   * <span> below we know that the menu should be open.
   *
   * When the user mouses onto a sibling Menu.Item/Submenu this will
   * be false and close.
   */
  const submenuRef = React.useRef<HTMLSpanElement>(null)
  const triggerIsActive = submenuRef.current?.contains(activeMenuItem as Node)

  return (
    <MenuButton keyboardDirection="horizontal">
      {(
        buttonOnClick,
        buttonOnKeyDown,
        buttonRef,
        menuVisible,
        menuAnchor,
        menuRef,
        menuItemOnKeyDown,
        menuItemEventHandler,
        menuOnVisible,
        menuItemMouseEnter,
        activeSubmenuItem
      ) => {
        /**
         * We need to augment/override the supplied Menu.Item/Submenus
         * to wire them up to the Menu.Button.
         *
         * -- activeMenuItem --
         * Applied to all Menu.Items (ignored) and Submenus which allows
         * the Submenu to know if one of its siblings has been hovered
         * over which is the close signal.
         *
         * -- onClick --
         * Using the menuItemEventHandler here allows the consumer to optionally
         * close just the current menu (vs all of the menus)
         *
         * -- onKeyDown --
         * Enables the Menu.Items passed in to interact with Menu.Button
         *  <left> to closes the current menu and focuses the trigger that opened it
         *  <tab>/<esc> close entire menu (to root Menu.Button)
         *  <home>/<end> first/last menu item selection
         *
         * -- onMouseEnter --
         * Menu.Button maintains the state of which Menu.Item (or Submenu) was last
         * hovered.  This allows Menu.Button to pass `activeSubmenuItem` to Submenus
         * which keeps the Submenu in an open state.
         */
        const renderMenuItem = (menuItem: React.ReactNode) => {
          if (!React.isValidElement(menuItem)) return null
          return React.cloneElement(menuItem, {
            activeMenuItem: activeSubmenuItem,
            onClick: menuItemEventHandler(menuItem.props.onClick),
            onKeyDown: callSerial(menuItemOnKeyDown, menuItem.props.onKeyDown),
            onMouseEnter: callSerial(
              menuItemMouseEnter,
              menuItem.props.onMouseEnter
            ),
          })
        }

        return (
          <span ref={submenuRef}>
            {/* Trigger */}
            <BaseMenuItem
              {...restProps}
              accessibilityText={accessibilityText}
              active={triggerIsActive || menuVisible}
              data-qa={`${dataQa}-submenu-trigger`}
              description={description || secondaryText}
              disabled={disabled}
              endElement={<MenuItemDisclosure kind="caretRight" />}
              forwardedRef={mergeRefs(buttonRef, forwardedRef)}
              onClick={callSerial(buttonOnClick, onClick)}
              onKeyDown={callSerial(buttonOnKeyDown, onKeyDown)}
              role="menuitem"
              text={text}
            />

            {/* Menu */}
            <MenuWithState
              alignment="start"
              anchor={menuAnchor}
              data-qa={`${dataQa}-submenu-menu`}
              forwardedRef={menuRef}
              location="after"
              locationFixed
              maxHeight={menuMaxHeight}
              minWidth={false} // eslint-disable-line react/jsx-boolean-value
              onVisible={menuOnVisible}
              visible={!disabled && (triggerIsActive || menuVisible)}
            >
              <MenuGroup
                accessibilityTitle={
                  menuGroupAccessibilityTitle || text || accessibilityText
                }
                data-qa={`${dataQa}-submenu-menu-group`}
              >
                {React.Children.map(restProps.children, renderMenuItem)}
              </MenuGroup>
            </MenuWithState>
          </span>
        )
      }}
    </MenuButton>
  )
}

MenuItemWithSubmenu.propTypes = {
  accessibilityText: PropTypes.string,
  activeMenuItem: CustomPropTypes.Element,
  badge: PropTypes.element,
  children: PropTypes.node.isRequired,
  'data-.*': PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  forwardedRef: CustomPropTypes.ReactRef,
  menuGroupAccessibilityTitle: PropTypes.string,
  menuMaxHeight: PropTypes.string,
  'on[A-Z].*': PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  secondaryText: PropTypes.string,
  startElement: PropTypes.node,
  text: PropTypes.string,
}

MenuItemWithSubmenu.defaultProps = {
  disabled: false,
}

MenuItemWithSubmenu.displayName = 'Menu.ItemWithSubmenu'
