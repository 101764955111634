import React from 'react'
import PropTypes from 'prop-types'

import {
  consoleWarn,
  deprecatedPropMessage,
  requiredPropMessage,
  warnInvalidThemeProp,
} from '../../../logging'
import { CustomPropTypes } from '../../../support'
import { useIsInk } from '../../../theming'
import { anchorTargets, AnchorTarget } from '../../../variables'

import { BaseMenuItem } from '../../../internal/components/BaseMenuItem'
import { iconWithImageKinds } from '../../../internal/components/IconOrImage'
import { MenuItemFileInput } from '../MenuItemFileInput'

import type { BadgeProps } from '../../Badge'
import type { DotBadgeProps } from '../../DotBadge'
import type { ConditionalTagRef, EventListenerProps } from '../../../types'

export interface MenuItemProps
  extends EventListenerProps<
    HTMLAnchorElement | HTMLButtonElement | HTMLInputElement
  > {
  /**
   * If you provide a value for 'accept', the Menu.Item will act as a file input.
   *
   * The prop accepts a string with a list of comma-separated content-type specifiers.
   * (file extensions prefixed with '.', valid MIME types without extensions)
   * e.g. '.js, image/*'.
   *
   * @deprecated - Use `accept` on `MenuItemFileInput` instead.
   */
  accept?: string
  /**
   * The text to present to assistive devices in order to identify the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  accessibilityText?: string
  /**
   * The "badge" element to display above the top-right of the Menu.Item.
   *
   * The normal use case for this would be to signify that there are notifications to be read
   * or actions to be taken, and a DotBadge element is provided to this prop to indicate such.
   *
   * This is only avaiable in the Olive theme.
   */
  badge?: React.ReactElement<DotBadgeProps | BadgeProps>
  /**
   * An optional description.
   */
  description?: string
  /**
   * Applies the 'disabled' attribute.
   */
  disabled?: boolean
  /**
   * The provided component will display at the end of the MenuItem.
   */
  endElement?: React.ReactNode
  /**
   * A React ref to assign to the HTML node representing the component.
   */
  forwardedRef?: ConditionalTagRef
  /**
   * URL for navigating. If a URL is supplied it renders as an anchor element,
   * otherwise it renders as a button element.
   */
  href?: string
  /**
   * Allows the user to select more than one file when the Menu.Item acts as a file input.
   * This prop is only used when a value is provided for 'accept'.
   *
   * @deprecated - Use `multiple` on `MenuItemFileInput` instead.
   */
  multiple?: boolean
  /**
   * The relationship of the linked URL of a anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel?: string
  /**
   * The secondary text of the Menu.Item.
   *
   * @deprecated – Use `description` instead.
   */
  secondaryText?: string
  /**
   * A boolean for indicating if the MenuItem is selected. Only used for styling
   */
  selected?: boolean
  /**
   * The provided component will display at the start of the MenuItem.
   */
  startElement?: React.ReactNode
  /**
   * The HTML link target (if an href is _not_ provided, this is ignored).
   * When target="_blank" use rel="noreferrer" or rel="noopener" to avoid the vulnerability.
   */
  target?: AnchorTarget
  /**
   * The text of the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  text?: string
  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*'?: React.EventHandler<
    React.SyntheticEvent<
      HTMLInputElement | HTMLAnchorElement | HTMLButtonElement
    >
  >
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

export const menuItemIcons = iconWithImageKinds
export const menuItemIconPositions = ['beforeText', 'afterText'] as const
export function MenuItem(props: MenuItemProps) {
  const {
    accept,
    accessibilityText,
    badge,
    description,
    disabled = false,
    endElement,
    forwardedRef,
    href,
    multiple = false,
    onChange,
    onClick,
    rel,
    secondaryText,
    selected = false,
    startElement,
    target,
    text,
    ...restProps
  } = props

  const isInk = useIsInk()

  const badgeNode = isInk ? undefined : badge

  if (!(text || accessibilityText)) {
    requiredPropMessage({
      component: 'Menu.Item',
      prop1: 'text',
      prop2: 'accessibilityText',
    })
  }

  if (secondaryText) {
    deprecatedPropMessage({
      component: 'Menu.Item',
      prop: 'secondaryText',
      newProp: 'description',
    })
  }

  if (isInk && badge) {
    warnInvalidThemeProp('Menu.Item', 'badge', 'Ink')
  }

  if (accept || multiple || onChange) {
    consoleWarn(
      'The (accept, multiple, onChange) props of the MenuItem component have been deprecated. To create a menu item as file input, instead use the MenuItemFileInput component.'
    )

    return (
      <MenuItemFileInput
        {...restProps}
        accept={accept}
        accessibilityText={accessibilityText}
        description={description || secondaryText}
        disabled={disabled}
        endElement={endElement}
        forwardedRef={forwardedRef}
        multiple={multiple}
        onChange={onChange}
        onClick={onClick}
        startElement={startElement}
        text={text}
      />
    )
  }

  return (
    <BaseMenuItem
      {...restProps}
      accessibilityText={accessibilityText}
      badge={badgeNode}
      data-selected-item={selected}
      description={description || secondaryText}
      disabled={disabled}
      endElement={endElement}
      forwardedRef={forwardedRef}
      href={href}
      onClick={onClick}
      rel={rel}
      role="menuitem"
      selected={selected}
      startElement={startElement}
      target={target}
      text={text}
    />
  )
}

MenuItem.targets = anchorTargets

MenuItem.propTypes = {
  accept: PropTypes.string,
  accessibilityText: PropTypes.string,
  badge: PropTypes.element,
  'data-.*': PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  endElement: PropTypes.node,
  forwardedRef: CustomPropTypes.ReactRef,
  href: PropTypes.string,
  multiple: PropTypes.bool,
  'on[A-Z].*': PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  rel: PropTypes.string,
  secondaryText: PropTypes.string,
  selected: PropTypes.bool,
  startElement: PropTypes.node,
  target: PropTypes.oneOf(anchorTargets),
  text: PropTypes.string,
}

MenuItem.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  accept: undefined,
  accessibilityText: undefined,
  badge: undefined,
  description: undefined,
  disabled: false,
  endElement: undefined,
  forwardedRef: undefined,
  href: undefined,
  multiple: false,
  onChange: undefined,
  onClick: undefined,
  rel: undefined,
  secondaryText: undefined,
  selected: false,
  startElement: undefined,
  target: undefined,
  text: undefined,
}

MenuItem.displayName = 'Menu.Item'
