import { breakpoints } from '../../../../utilities'
import type { Tokens } from '../../../types'
import colors from '../colors'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config

  return {
    default: {
      wrap: {
        padding: '7px 16px 7px 24px',
        borderRadius: '4px',
        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          padding: '3px 16px 3px 24px',
        },
      },

      icon: {
        marginRight: '16px',
        alignItems: 'center',
        display: 'flex',
        minHeight: tokens.button.defaultSize,
        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          minHeight: tokens.button.mobileSize,
        },
      },

      content: {
        padding: '8px 0',
        minHeight: tokens.button.defaultSize,
        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          padding: '12px 0',
          minHeight: tokens.button.mobileSize,
        },
      },

      action: {
        marginLeft: '24px',
        [breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)]: {
          marginLeft: '-16px',
        },
      },

      closeButton: {
        marginLeft: '24px',
        alignItems: 'center',
        display: 'flex',
        minHeight: tokens.button.defaultSize,
        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          minHeight: tokens.button.mobileSize,
        },
      },
    },

    information: {
      wrap: {
        background: colors.semanticNeutral[10],
        border: `1px solid ${colors.semanticNeutral[100]}`,
      },
      icon: {
        color: colors.semanticNeutral[60],
      },
      iconKind: 'pebbleInfo',
    },

    warning: {
      wrap: {
        background: colors.extendedYellow[10],
        border: `1px solid ${colors.extendedYellow[50]}`,
      },
      icon: {
        color: colors.extendedYellow[60],
      },
      iconKind: 'pebbleWarning',
    },

    success: {
      wrap: {
        background: colors.semanticGreen[10],
        border: `1px solid ${colors.semanticGreen[60]}`,
      },
      icon: {
        color: colors.semanticGreen[60],
      },
      iconKind: 'pebbleCheck',
    },

    danger: {
      wrap: {
        background: colors.semanticCoolRed[10],
        border: `1px solid ${colors.semanticCoolRed[60]}`,
      },
      icon: {
        color: colors.semanticCoolRed[60],
      },
      iconKind: 'pebbleError',
    },
  } as const
}
