import * as React from 'react'
import PropTypes from 'prop-types'
import { CustomPropTypes } from '../../support'
import type { DivForwardRef } from '../../types'
import { MenuButton } from './MenuButton'
import { MenuCustomItem } from './MenuCustomItem'
import { MenuGroup } from './MenuGroup'
import { MenuItem } from './MenuItem'
import { MenuItemDisclosure } from './MenuItemDisclosure'
import { MenuItemFileInput } from './MenuItemFileInput'
import { MenuItemWithSubmenu } from './MenuItemWithSubmenu'
import { MenuWithState } from './MenuWithState'

export const menuLocations = ['above', 'below', 'before', 'after'] as const
export type MenuLocation = typeof menuLocations[number]

export const menuAlignments = ['start', 'center', 'end'] as const
export type MenuAlignment = typeof menuAlignments[number]

export interface MenuProps {
  /**
   * The alignment of the Menu along the edge of its anchor element.
   *
   * For locations 'above' and 'below':
   * - 'start': left-aligns the Menu and the anchor element
   * - 'center': centers the Menu along the width of the anchor element
   * - 'end': right-aligns the Menu and the anchor element
   *
   * For locations 'before' and 'after':
   * - 'start': top-aligns the Menu and the anchor element
   * - 'center': centers the Menu along the height of the anchor element
   * - 'end': bottom-aligns the Menu and the anchor element
   */
  alignment?: MenuAlignment
  /**
   * The Element around which the Menu will be positioned.
   */
  anchor?: HTMLElement | null
  /**
   * The 'children' prop accepts arbitrary nodes, however the recommendation is to
   * use Menu.Group(s).  When using Menu.Item or its variants you *must* render them
   * within a Menu.Group.
   */
  children: React.ReactNode
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * A React ref to assign to the HTML node representing the Menu element.
   */
  forwardedRef?: DivForwardRef
  /**
   * The preferred location of the Menu relative to its anchor element.
   */
  location?: MenuLocation
  /**
   * Determines whether the Menu will adjust its location when it starts to overlap
   * its anchor element (by default it will "flip" to the opposite side of the
   * anchor element).
   */
  locationFixed?: boolean
  /**
   * Max height of the menu in any accepted numeric or percentage CSS unit.
   */
  maxHeight?: string
  /**
   * Automatically calculates the max height of the menu. Makes menu scrollable if it does not have
   * enough height to fully open.
   */
  maxHeightAuto?: boolean
  /**
   * By default, the minimum width of the Menu will be the width of its anchor element.
   *
   * Set this prop to `false` in order to remove this constraint.
   */
  minWidth?: boolean
  /**
   * Passed to underlying Popper component's onFirstUpdate prop. Will be called after Popper
   * has positioned the element the first time.
   */
  onVisible?: () => void
  /**
   * When a Menu is displayed without providing an Element for the 'anchor' prop,
   * apply the CSS property { position: static; } to the Menu.
   *
   * The presence of this prop has no effect when an Element is provided for 'anchor'.
   *
   * DETAILS:
   * The CSS positioning properties for the Menu component will be updated in a
   * future major release.
   *  - currently: if a Menu is displayed without providing an Element for the
   *    'anchor' prop, it is positioned with the CSS properties:
   *    { position: absolute; top: 0; left: 0; z-index: 200; }
   *  - upcoming [FUTURE MAJOR RELEASE]: if a Menu is displayed without providing
   *    an Element for the 'anchor' prop, none of the above-referenced CSS properties
   *    will be applied, it will be up to the consumer to appropriately position
   *    the Menu (or its wrapper)
   *  - recommended: if you are displaying a Menu without providing an Element for
   *    the 'anchor' prop, you can provide the boolean prop 'positionStatic' to apply
   *    the CSS property { position: static; } to the Menu (ignoring the values
   *    that are being set for 'top', 'left', 'z-index')
   */
  positionStatic?: boolean
  /**
   * When the menu becomes visible its width is determined by the content. With
   * `preserveWidth` it will take the initial width and keep it as a minimum for as
   * long as the menu is open.
   *
   * This can be useful when menu's content changes rapidly, as in a searchable list.
   * It removes unneccessary resizing to minimize distraction for the user.
   */
  preserveWidth?: boolean
  /**
   * @deprecated use forwardedRef instead
   */
  ref?: DivForwardRef
  /**
   * Display the Menu.
   */
  visible?: boolean
}

export type MenuComponent = typeof Menu

/**
 * Menus provide a list of related actions in a limited space.
 */
export function Menu(props: MenuProps) {
  return <MenuWithState {...props} />
}

Menu.propTypes = {
  alignment: PropTypes.oneOf(menuAlignments),
  anchor: CustomPropTypes.Element,
  children: PropTypes.node.isRequired,
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  location: PropTypes.oneOf(menuLocations),
  locationFixed: PropTypes.bool,
  maxHeight: PropTypes.string,
  maxHeightAuto: PropTypes.bool,
  minWidth: PropTypes.bool,
  positionStatic: PropTypes.bool,
  preserveWidth: PropTypes.bool,
  visible: PropTypes.bool,
}

Menu.defaultProps = {
  alignment: 'start',
  location: 'below',
  locationFixed: false,
  maxHeightAuto: false,
  minWidth: true,
  positionStatic: false,
  preserveWidth: false,
  visible: false,
}

Menu.Button = MenuButton
Menu.CustomItem = MenuCustomItem
Menu.Group = MenuGroup
Menu.Item = MenuItem
Menu.ItemDisclosure = MenuItemDisclosure
Menu.ItemFileInput = MenuItemFileInput
Menu.ItemWithSubmenu = MenuItemWithSubmenu
