import React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { useThemeStyles } from '../../theming'
import baseStyles from './styles'

export interface HeaderRightProps {
  /**
   * Children in the right side of the Header.
   */
  children: React.ReactNode
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

export function HeaderRight(props: HeaderRightProps) {
  const { children, ...restProps } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'Header')

  return (
    <div {...dataProps(restProps)} css={styles.HeaderRight}>
      {children}
    </div>
  )
}

HeaderRight.propTypes = {
  children: PropTypes.node.isRequired,
  'data-.*': PropTypes.string,
}

HeaderRight.defaultProps = {
  'data-.*': undefined,
}

HeaderRight.displayName = 'Header.Right'
