import * as React from 'react'
import { HeaderItem, HeaderMobileFooterLinks } from '../types'
import { TranslationKeys } from '@ds/comp-private'
import { Locale, TranslateFunction } from '@ds/base'
import type { FooterLink } from '../../Footer'
import {
  defaultLinkTarget,
  defaultLinkRel,
} from '../../Footer/footerConfiguration'

/*

    Create a HeaderItem from footer links that can be displayed in the mobile menu.

    All links are rendered as rows of a submenu. The copyright and passive links
    are rendered in deeper menu (as simple text).

*/

export function createFooterItem(
  mobileFooter: HeaderMobileFooterLinks,
  locale: Locale,
  translate: TranslateFunction<TranslationKeys>
): HeaderItem {
  const linkItems = createLinkItems(mobileFooter, locale, translate)
  const appInfoItem = createAppInfoItem(mobileFooter, locale, translate)
  const rootItem: HeaderItem = {
    itemId: 'FOOTER',
    text: translate('HDR:Footer'),
    subItems: [...linkItems, appInfoItem],
  }
  return rootItem
}

function createLinkItems(
  footer: HeaderMobileFooterLinks,
  locale: Locale,
  translate: TranslateFunction<TranslationKeys>
): HeaderItem[] {
  const activeItems: HeaderItem[] = footer.links
    .filter((link) => !!link.url)
    .map((link) => {
      const qa =
        (footer.dataQaOverrides && footer.dataQaOverrides[link.id]) ||
        `footer-link-${link.id}`
      const href = typeof link.url === 'function' ? link.url(locale) : link.url
      return {
        itemId: link.id,
        text: linkText(link, locale, translate),
        href,
        rel: link.rel || defaultLinkRel(),
        target: link.target || defaultLinkTarget(),
        'data-qa': qa,
      }
    })
  return activeItems
}

function createAppInfoItem(
  footer: HeaderMobileFooterLinks,
  locale: Locale,
  translate: TranslateFunction<TranslationKeys>
): HeaderItem {
  const passiveItemTexts: string[] = footer.links
    .filter((link) => !link.url)
    .map((link) => linkText(link, locale, translate))
  const copyright = translate(
    'Copyright © {{CURRENT_YEAR}} DocuSign, Inc. All rights reserved',
    {
      CURRENT_YEAR: new Date().getFullYear().toString(),
    }
  )
  return {
    itemId: 'APP_INFO',
    text: translate('HDR:AppInfo'),
    subItems: (
      <AppInfo items={[copyright, ...passiveItemTexts]} translate={translate} />
    ),
  }
}

function linkText(
  link: FooterLink,
  locale: Locale,
  translate: TranslateFunction<TranslationKeys>
) {
  return link.customTranslate
    ? link.customTranslate(locale)
    : translate(link.textId!)
}

const AppInfo: React.FunctionComponent<{
  items: string[]
  translate: TranslateFunction<TranslationKeys>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}> = ({ items, translate }) => (
  <ul style={{ listStyle: 'none', padding: 20 }}>
    {items.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <li key={index} style={{ marginBottom: 20 }}>
        {item}
      </li>
    ))}
  </ul>
)
