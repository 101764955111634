import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { ClassNames } from '@emotion/core'
import { CustomPropTypes } from '../../support'
import { useThemeStyles } from '../../theming'
import { times } from '../../variables'
import baseStyles from './styles'

interface MenuAnimationProps {
  /**
   * Ref that designates which element will be animated
   */
  animationRef: React.Ref<HTMLElement>
  /**
   * Function passed to the onExited prop of CSSTransition
   */
  onExited?: () => void
  /**
   * Dictates when the animation should enter/exit
   */
  visible: boolean
  /**
   * Children to be rendered when visible
   */
  children: React.ReactNode
}

export function MenuAnimation(props: MenuAnimationProps) {
  const { animationRef, children, onExited, visible } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'Menu')

  return (
    <ClassNames>
      {({ css }) => (
        <CSSTransition
          appear
          in={visible}
          nodeRef={animationRef}
          timeout={{
            enter: times.Menu.enter,
            exit: times.Menu.exit,
          }}
          classNames={{
            appear: css(styles.animation.enter),
            appearActive: css(styles.animation.enterActive),
            appearDone: css(styles.animation.enterDone),

            enter: css(styles.animation.enter),
            enterActive: css(styles.animation.enterActive),
            enterDone: css(styles.animation.enterDone),

            exit: css(styles.animation.exit),
            exitActive: css(styles.animation.exitActive),
            exitDone: css(styles.animation.exitDone),
          }}
          onExited={onExited}
          unmountOnExit
        >
          {children}
        </CSSTransition>
      )}
    </ClassNames>
  )
}

MenuAnimation.propTypes = {
  animationRef: CustomPropTypes.ReactRef.isRequired,
  children: PropTypes.node.isRequired,
  onExited: PropTypes.func,
  visible: PropTypes.bool.isRequired,
}

MenuAnimation.defaultProps = {
  onExited: undefined,
}
