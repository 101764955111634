import * as React from 'react'
import {
  Header as DsUiHeader,
  Menu,
  AnyArgsVoidReturn,
  AnchorOrButtonOnClickHandler,
  Icon,
  DotBadge,
} from '@ds/ui'
import { isFunction } from 'lodash'
import {
  LabeledAction,
  HeaderTranslateFunction,
  HeaderHelpActions,
} from '../types'
import { isActionElement, isCustomContent } from '../utils'
import { useEventListener, useTimer } from '@ds/react-utils'
import styles from '../styles'

/*

    A menu of help options which reveals itself from a
    button on the header.

*/

export interface HeaderHelpMenuProps {
  helpActions: HeaderHelpActions
  showBadge?: boolean
  translate: HeaderTranslateFunction
}

export const HeaderHelpMenuButton: React.FunctionComponent<HeaderHelpMenuProps> =
  (props) => {
    const actions = (props.helpActions as LabeledAction[]) || []
    const menuTriggerButtonRef = React.useRef<AnyArgsVoidReturn>()
    return (
      <div data-header-help-trigger={true}>
        <Menu.Button>
          {(
            buttonOnClick: AnyArgsVoidReturn,
            buttonOnKeyDown: AnyArgsVoidReturn,
            buttonRef: React.RefObject<HTMLAnchorElement | HTMLButtonElement>,
            menuVisible: boolean,
            menuAnchor: HTMLElement,
            menuRef: React.RefObject<HTMLDivElement>,
            menuItemOnKeyDown: AnyArgsVoidReturn,
            menuItemEventHandler: (
              ...args: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
            ) => AnchorOrButtonOnClickHandler,
            menuOnVisible: () => void
          ) => {
            menuTriggerButtonRef.current = buttonOnClick
            return (
              <>
                <DsUiHeader.ActionItem
                  iconElement={
                    <Icon kind="help" size={styles.headerStandardIconSize} />
                  }
                  text={props.translate('help')}
                  hideText={true}
                  onClick={buttonOnClick}
                  marginLeft={false}
                  forwardedRef={buttonRef}
                  badge={
                    props.showBadge ? (
                      <DotBadge color="red" data-qa="header-help-badge" />
                    ) : undefined
                  }
                  onKeyDown={buttonOnKeyDown}
                  data-qa="header-help-button"
                />

                <Menu
                  location="below"
                  alignment="end"
                  visible={menuVisible && !!menuAnchor}
                  anchor={menuAnchor}
                  forwardedRef={menuRef}
                  onVisible={menuOnVisible}
                  minWidth={false}
                  locationFixed={true}
                  maxHeight="calc(100vh - 100px)"
                >
                  {isCustomContent(props.helpActions)
                    ? renderCustomContent()
                    : renderActions(menuItemOnKeyDown, menuItemEventHandler)}
                </Menu>
              </>
            )
          }}
        </Menu.Button>
      </div>
    )

    function renderActions(
      menuItemOnKeyDown: AnyArgsVoidReturn,
      menuItemEventHandler: (
        ...args: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
      ) => AnchorOrButtonOnClickHandler
    ) {
      return (
        <Menu.Group accessibilityTitle={props.translate('HDR:help-selector')}>
          {actions.map((action) =>
            renderAction(menuItemOnKeyDown, menuItemEventHandler, action)
          )}
        </Menu.Group>
      )
    }

    function renderAction(
      menuItemOnKeyDown: AnyArgsVoidReturn,
      menuItemEventHandler: (
        ...args: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
      ) => AnchorOrButtonOnClickHandler,
      action: LabeledAction
    ) {
      return (
        <Menu.Item
          key={action.itemId}
          text={action.text}
          onClick={
            action.onClick
              ? menuItemEventHandler(
                  (event: Event, closeMenu: () => unknown) => {
                    event.preventDefault()
                    if (action.onClick) {
                      action.onClick()
                    }
                    closeMenu()
                  }
                )
              : undefined
          }
          href={action.href}
          rel={action.rel}
          target={action.target}
          onKeyDown={menuItemOnKeyDown}
          data-qa={action['data-qa'] || `header-help-${action.itemId}-button`}
        />
      )
    }

    function renderCustomContent() {
      return (
        <CustomContentActionListener
          translate={props.translate}
          onActionClick={() => {
            if (menuTriggerButtonRef.current) {
              menuTriggerButtonRef.current()
            }
          }}
        >
          {props.helpActions}
        </CustomContentActionListener>
      )
    }
  }

const CustomContentActionListener: React.FunctionComponent<{
  onActionClick: () => void
  translate: HeaderTranslateFunction
}> = (props) => {
  const { children, onActionClick, translate } = props

  const [contentComponentEl, setContentComponentEl] =
    React.useState<HTMLDivElement | null>()

  const { runLater } = useTimer()

  useEventListener(
    'click',
    (event) => {
      if (!isFunction(children) && isActionElement(event.target! as Element)) {
        runLater(() => onActionClick(), 0)
      }
    },
    contentComponentEl
  )

  if (isFunction(children)) {
    return children(() => runLater(() => onActionClick(), 0))
  }

  return (
    <Menu.Group accessibilityTitle={translate('HDR:Help')}>
      <Menu.CustomItem>
        <div ref={setContentComponentEl}>{children}</div>
      </Menu.CustomItem>
    </Menu.Group>
  )
}
