import type { CobrandingConfig, CobrandingTokens } from '../types'
import applyBrightness from './applyBrightness'
import contrastingGrayscaleFillColor from './contrastingGrayscaleFillColor'
import contrastingGrayscaleTextColor from './contrastingGrayscaleTextColor'

export default (cobrandingConfig: CobrandingConfig): CobrandingTokens => {
  // #region Header colors
  const { headerBackgroundColor } = cobrandingConfig

  const HEADER_HOVER_BRIGHTNESS = 1.3
  const HEADER_ACTIVE_BRIGHTNESS = 0.7

  const headerTextColor =
    cobrandingConfig.headerTextColor ||
    contrastingGrayscaleTextColor(headerBackgroundColor)

  const quickActionsText = contrastingGrayscaleTextColor(headerBackgroundColor)

  const headerBackgroundHoverColor = applyBrightness(
    cobrandingConfig.headerBackgroundColor,
    HEADER_HOVER_BRIGHTNESS
  )

  const headerBackgroundActiveColor = applyBrightness(
    cobrandingConfig.headerBackgroundColor,
    HEADER_ACTIVE_BRIGHTNESS
  )

  const headerFillColor = contrastingGrayscaleFillColor(headerBackgroundColor)
  // #endregion

  // #region InvertedButton
  const invertedButtonBackgroundColor = headerTextColor
  const invertedButtonTextColor = contrastingGrayscaleTextColor(headerTextColor)
  // #endregion

  // #region Button colors
  const BUTTON_HOVER_BRIGHTNESS = 0.967
  const BUTTON_ACTIVE_BRIGHTNESS = 0.934

  const { buttonMainBackgroundColor, buttonPrimaryBackgroundColor } =
    cobrandingConfig

  const buttonMainTextColor =
    cobrandingConfig.buttonMainTextColor ||
    contrastingGrayscaleTextColor(buttonMainBackgroundColor)

  const buttonPrimaryTextColor =
    cobrandingConfig.buttonPrimaryTextColor ||
    contrastingGrayscaleTextColor(buttonPrimaryBackgroundColor)

  const buttonMainBackgroundHoverColor = applyBrightness(
    buttonMainBackgroundColor,
    BUTTON_HOVER_BRIGHTNESS
  )

  const buttonMainBackgroundActiveColor = applyBrightness(
    buttonMainBackgroundColor,
    BUTTON_ACTIVE_BRIGHTNESS
  )

  const buttonPrimaryBackgroundHoverColor = applyBrightness(
    buttonPrimaryBackgroundColor,
    BUTTON_HOVER_BRIGHTNESS
  )

  const buttonPrimaryBackgroundActiveColor = applyBrightness(
    buttonPrimaryBackgroundColor,
    BUTTON_ACTIVE_BRIGHTNESS
  )
  // #endregion

  return {
    headerBackgroundColor,
    headerBackgroundHoverColor,
    headerBackgroundActiveColor,
    headerTextColor,
    headerFillColor,

    logoUrl: cobrandingConfig.logoUrl,
    showLogoOnWhite: cobrandingConfig.showLogoOnWhite,

    invertedButtonBackgroundColor,
    invertedButtonTextColor,

    quickActionsTextColor: quickActionsText,

    buttonMainBackgroundColor,
    buttonMainBackgroundHoverColor,
    buttonMainBackgroundActiveColor,
    buttonMainTextColor,

    buttonPrimaryBackgroundColor,
    buttonPrimaryBackgroundHoverColor,
    buttonPrimaryBackgroundActiveColor,
    buttonPrimaryTextColor,
  }
}
