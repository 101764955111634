import React from 'react'
import PropTypes from 'prop-types'
import { useThemeStyles } from '../../../theming'
import { variant } from '../../../utilities'
import type { AvatarSize } from '../Avatar'
import baseStyles from './styles'

const avatarSizes = ['xxlarge', 'xlarge', 'large', 'medium', 'small'] as const

export interface AvatarBadgeProps {
  /**
   * The text to present to assistive devices in order to identify the AvatarBadge.
   */
  accessibilityText?: string
  /**
   * The size of the Avatar Badge.
   */
  size?: AvatarSize
}

export function AvatarBadge(props: AvatarBadgeProps) {
  const { accessibilityText, ...restProps } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles)

  const badgeStyles = [
    styles.default.badge,
    restProps.size && styles[variant('size', restProps.size)].badge,
  ]

  const badgeText = accessibilityText && (
    <span css={styles.hidden}>{accessibilityText}</span>
  )

  return <span css={badgeStyles}>{badgeText}</span>
}

AvatarBadge.propTypes = {
  accessibilityText: PropTypes.string,
  size: PropTypes.oneOf(avatarSizes),
}

AvatarBadge.defaultProps = {
  accessibilityText: undefined,
  size: undefined,
}

AvatarBadge.displayName = 'Avatar.Badge'
