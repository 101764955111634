import { styles } from '../../utilities'
import { zIndexes } from '../../variables'
import colors from '../../theming/docusign-themes/olive/colors'

export default () =>
  ({
    Header: {
      default: {
        backgroundColor: colors.DocuSignBlue,
        flex: '0 0 auto',
        zIndex: zIndexes.aboveBaseLevel,
      },

      wrap: {
        alignItems: 'center',
        color: colors.white,
        display: 'flex',
        flexDirection: 'row',
        height: '52px',
        justifyContent: 'space-between',
        margin: '0 auto',
        minWidth: '320px',
        maxWidth: '1506px',
        padding: '0 12px 0 24px',
        position: 'relative',
      },

      fullWidth: {
        maxWidth: 'none',
        padding: '0 16px 0 8px',
      },
    },

    HeaderLeft: {
      alignItems: 'center',
      display: 'flex',
      height: 'inherit',
      flex: '1 1 25%',
      minWidth: '196px',
    },

    HeaderCenter: {
      flex: '1 1 auto',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },

    HeaderRight: {
      alignItems: 'center',
      display: 'flex',
      flex: '1 1 25%',
      justifyContent: 'flex-end',
      minWidth: '243px',
    },

    Nav: {
      alignContent: 'center',
      display: 'flex',
      flexWrap: 'nowrap',
      listStyle: 'none',
      margin: 0,
      padding: 0,

      '> li': {
        margin: '0 12px',
      },
    },

    hidden: styles.visuallyHidden,
  } as const)
