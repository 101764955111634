import React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { useThemeStyles } from '../../theming'
import { isChildTypeEqualToComponentType } from '../../utilities'
import { HeaderActionItem } from './HeaderActionItem'
import { HeaderTitle } from './HeaderTitle'
import baseStyles from './styles'

export interface HeaderLeftProps {
  /**
   * Children in the left side of the Header.
   */
  children: React.ReactNode
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

export function HeaderLeft(props: HeaderLeftProps) {
  const { children, ...restProps } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles)

  const decoratedChildren = React.Children.map(children, (child, index) => {
    const previousChild = React.Children.toArray(children)[index - 1]
    if (
      React.isValidElement(child) &&
      isChildTypeEqualToComponentType(child, HeaderTitle) &&
      React.isValidElement(previousChild) &&
      isChildTypeEqualToComponentType(previousChild, HeaderActionItem)
    ) {
      return React.cloneElement(child, {
        precededByHeaderAction: true,
      })
    }

    return child
  })

  return (
    <div {...dataProps(restProps)} css={styles.HeaderLeft}>
      {decoratedChildren}
    </div>
  )
}

HeaderLeft.propTypes = {
  children: PropTypes.node.isRequired,
  'data-.*': PropTypes.string,
}

HeaderLeft.defaultProps = {
  'data-.*': undefined,
}

HeaderLeft.displayName = 'Header.Left'
