import { styles } from '../../../utilities'
import colors from '../../../theming/docusign-themes/olive/colors'
import { CreateFocusCss } from '../../../styles'

export default () =>
  ({
    wrap: {
      alignItems: 'stretch',
      display: 'flex',
      height: '52px',
    },

    label: styles.visuallyHidden,

    button: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      color: colors.white,
      cursor: 'pointer',
      display: 'block',
      padding: '0 12px',
      transition: 'background-color 0.2s ease',

      '&:hover, &:active, &[aria-expanded="true"]': {
        backgroundColor: colors.DocuSignBlueLight,
      },

      ...CreateFocusCss({
        outlineColor: colors.white,
        outlineStyle: 'dotted',
        outlineWidth: '1px',
        outlineOffset: '-4px',
      }),
    },
  } as const)
