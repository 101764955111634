import { CreateFocusCss } from '../../../../styles'
import type { HeaderLogoProps } from '../../../../components/Header'
import type { HeaderContextValue } from '../../../../components/Header/HeaderContext'
import type { Tokens } from '../../../types'
import colors from '../colors'

export default (config: {
  props?: HeaderLogoProps & HeaderContextValue
  tokens: Tokens
}) => {
  const { props, tokens } = config

  return {
    link: {
      paddingLeft: '12px',
      paddingTop: '3px',

      ...CreateFocusCss({
        outlineColor: props?.dark
          ? colors.semanticWhite[100]
          : colors.semanticNeutral.fade[60],
        outlineStyle: 'solid',
        outlineWidth: '2px',
        outlineOffset: '2px',
      }),
    },

    image: {
      width: '111px',

      svg: {
        fill: props?.dark
          ? colors.semanticWhite[100]
          : colors.semanticBlack[100],
      },
    },

    text: {
      borderLeftColor: props?.dark
        ? colors.semanticWhite.fade[45]
        : colors.semanticNeutral.fade[45],
      color: props?.dark
        ? colors.semanticWhite[100]
        : colors.semanticNeutral[100],
      fontFamily: tokens.font.body,
      fontSize: '16px',
      fontWeight: 500,
      height: '24px',
      lineHeight: '24px',
      marginLeft: '12px',
      paddingLeft: '12px',
    },
  } as const
}
